export const allCardsEvo = [
  "White Vanara Evolved",
  "Paula, Gentle Warmth",
  "Storied Falconer Evolved",
  "Grasshopper Conductor Evolved",

  "Celia, Hope's Strategist",
  "Innocent Princess Prim Evolved",
  "Dario, Demon Count Evolved",
  "Axe Princess Evolved",

  "Ceridwen, Eternity Hunter Evolved",
  "Mysterian Whitewyrm",
  "Bergent, Onion Patchmaster Evolved",
  "Witch of Foresight Evolved",
  "Grea, Mysterian Dragoness Evolved",
  "Mysterian Summoner Vayle Evolved",

  "Jerva of Draconic Mail Evolved",
  "Virtuous Lindworm",
  "Dragoncleaver Roy Evolved",
  "Heroic Dragonslayer Evolved",
  "Romelia, Lightning Striker Evolved",
  "Katakura Kojuro Evolved",

  "Kind Queen Vania",
  "Oldblood King Evolved",
  "Big Soul Hunter Evolved",
  "Orator of the Bones Evolved",

  "Jeanne, Beacon of Salvation Evolved",
  "Cerynelan Lighthind",
  "Jeweled Priestess Evolved",
  "Lycaon Evolved",

  "Marduk Evolved",
  "Paradise Vanguard Evolved",
  "Suttungr Evolved",
  "Valkyrie of Chaos Evolved",

  "Orchis, Resolute Puppet",
  "Zwei, Murderous Puppet Evolved",
  "Michelle, the Mind Reader Evolved",
  "Insane Dark Elf Evolved",

  "Dionne, Dancing Blade Evolved",
  "Roland the Incorruptible Evolved",
  "Azord, Duke of the Mists Evolved",
  "Mana Pistol Merc Evolved",

  "Sweet-Tooth Medusa Evolved",
  "Unbodied Witch Evolved",
  "Elusa, Magic Wunderkind Evolved",
  "Rabbit Mage Evolved",

  "Azi Dahaka Evolved",
  "Annerose Evolved",
  "Elios, Loyal Dragoon Evolved",
  "Geovore Evolved",

  "Crimson Rose Queen Evolved",
  "Tartarus, the Tormentor Evolved",
  "Chris Pumpkinhead Evolved",
  "Marian the Mummy Evolved",

  "Godsworn Alexiel Evolved",
  "Eidolon of Madness Evolved",
  "Collete, Holy Gunner Evolved",
  "Temple Windbear Evolved",

  "Alterplane Arbiter Evolved",
  "Sahaquiel Evolved",
  "Reina, Evolution's Herald Evolved",
  "High Enchantress Evolved",

  "Ladica, the Stoneclaw Evolved",
  "Setus, the Beastblade Evolved",
  "Blossom Spirit Evolved",
  "Forest Hermit Evolved",

  "Bayleon, Sovereign Light Evolved",
  "Leod, the Crescent Blade Evolved",
  "Troya, Thunder of Hagelberg Evolved",
  "Dauntless Commander Evolved",

  "Tetra, Sapphire Rebel Evolved",
  "Eleanor, Cosmic Flower Evolved",
  "Displacer Bot Evolved",
  "Magiblade Witch Evolved",

  "Valdain, Cursed Shadow Evolved",
  "Marion, Elegant Dragonewt Evolved",
  "Hoarfrost Triceratops Evolved",
  "Doting Dragoneer Evolved",

  "Mono, Garnet Rebel Evolved",
  "Doublame, Duke and Dame Evolved",
  "Nicola, Forbidden Strength Evolved",
  "Berserk Demon Evolved",

  "Limonia, Flawed Saint Evolved",
  "Marione, Light of Balance Evolved",
  "Robofalcon Evolved",
  "Dark Bishop Evolved",

  "Viridia Magna Evolved",
  "Maisha, Hero of Purgation Evolved",
  "Robogoblin Evolved",
  "Aldis, Trendsetting Seraph Evolved",

  "Momoka Sakurai Evolved",
  "Akiha Ikebukuro Evolved",
  "Nene Kurihara Evolved",

  "Yukimi Sajo Evolved",
  "Kako Takafuji Evolved",
  "Seira Mizuki Evolved",

  "Suzuho Ueda Evolved",
  "Miria Akagi Evolved",
  "Kumiko Matsuyama Evolved",

  "Aiko Takamori Evolved",
  "Yuzu Kitami Evolved",
  "Yumi Aiba Evolved",
  "Kana Imai Evolved",

  "Kyoko Igarashi Evolved",
  "Uzuki Shimamura Evolved",
  "Karen Hojo Evolved",
  "Miho Kohinata Evolved",

  "Shiki Ichinose Evolved",
  "Kanade Hayami Evolved",
  "Hina Araki Evolved",
  "Rika Jougasaki Evolved",

  "Yui Ohtsuki Evolved",
  "Tsukasa Kiryu Evolved",
  "Yuka Nakano Evolved",
  "Noa Takamine Evolved",

  "Ranko Kanzaki Evolved",
  "Takumi Mukai Evolved",
  "Aki Yamato Evolved",
  "Syoko Hoshi Evolved",

  "Shin Sato Evolved",
  "Nana Abe Evolved",
  "Haru Yuuki Evolved",
  "Layla Evolved",

  "Lymaga, Forest Champion Evolved",
  "Wildwood Matriarch Evolved",
  "Woodland Cleaver Evolved",
  "Mallet Monkey Evolved",

  "Ralmia, Sonic Racer Evolved",
  "Hero of Antiquity Evolved",
  "Quickdraw Maven Evolved",
  "Samurai Outlaw Evolved",

  "Mysteria, Magic Founder Evolved",
  "Curse Crafter Evolved",
  "Demoncaller Evolved",
  "Charming Gentlemouse Evolved",

  "Garyu, Surpreme Dragonkin Evolved",
  "Wyrm God of the Skies Evolved",
  "Ice Dancing Dragonewt Evolved",
  "Dragonblader Evolved",

  "Ginsetsu, Great Fox Evolved",
  "Shuten-Doji Evolved",
  "Cougar Pelt Warrior Evolved",
  "Zashiki-Warashi Evolved",

  "Karula, Arts Master Evolved",
  "Phantom Blade Wielder Evolved",
  "Holy Lancer Evolved",
  "Barrage Brawler Evolved",

  "Badb Catha Evolved",
  "Mithra, Daybreak Diety Evolved",
  "Chaht, Ringside Announcer Evolved",
  "Bazooka Goblins Evolved",

  "Izudia, Omen of Unkilling Evolved",
  "Apostle of Unkilling Evolved",
  "Noah, Vengeful Puppeteer Evolved",
  "Flower Doll Evolved",

  "Octrice, Omen of Usurpation Evolved",
  "Apostle of Usurpation Evolved",
  "Geno, Machine Artisan Evolved",
  "Captain Meteo Evolved",

  "Raio, Omen of Truth Evolved",
  "Apostle of Truth Evolved",
  "Iron Staff Mechanic Evolved",
  "Servant of Destruction Evolved",

  "Galmieux, Omen of Disdain Evolved",
  "Apostle of Disdain Evolved",
  "Cursed Stone Evolved",
  "Airship Whale Evolved",

  "Rulenye, Omen of Silence Evolved",
  "Apostle of Silence Evolved",
  "Masked Puppet Evolved",
  "Servant of Lust Evolved",

  "Marwynn, Omen of Repose Evolved",
  "Apostle of Repose Evolved",
  "Unidentified Subject Evolved",
  "Demon's Epitaph Evolved",

  "Mjerrabaine, Omen of One Evolved",
  "Apostle of Craving Evolved",
  "Rosa, Mech Wing Maiden Evolved",
  "Cat Cannoneer Evolved",

  "Deepwood Anomaly Evolved",
  "King Elephant Evolved",
  "Sukuna, Brave and Small Evolved",
  "Fita the Gentle Elf Evolved",
  "Mars, Silent Flame General Evolved",
  "Barbarossa Evolved",
  "Shrouded Assassin Evolved",
  "Tristan of the Round Table Evolved",
  "Wordwielder Ginger Evolved",
  "Europa Evolved",
  "Magic Illusionist Evolved",
  "Dazzling Healer Evolved",
  "Python Evolved",
  "Lævateinn Dragon, Defense Form Evolved",
  "Lævateinn Dragon, Blast Form Evolved",
  "Star Phoenix Evolved",
  "Cetus Evolved",
  "Hippocampus Evolved",
  "Howling Demon Evolved",
  "Stheno Evolved",
  "Fenrir Evolved",
  "Frogbat Evolved",
  "Dark Jeanne Evolved",
  "Zoe, Princess of Goldenia Evolved",
  "Star Priestess Evolved",
  "Mist Shaman Evolved",
  "Israfil Evolved",
  "Grimnir, War Cyclone Evolved",
  "Goblin Princess Evolved",
  "Owlcat Evolved",

  "Cosmos Fang Evolved",
  "Slade Blossoming Wolf Evolved",
  "Gerbera Bear Evolved",
  "Tweedle Dum, Tweedle Dee Evolved",
  "Valiant Fencer Evolved",
  "Amerro, Spear Knight Evolved",
  "Mach Knight Evolved",
  "Bladed Hedgehog Evolved",
  "Mystic King Evolved",
  "Milady, Mystic Queen Evolved",
  "Magical Knight Evolved",
  "Witch of Sweets Evolved",
  "Lævateinn Dragon Evolved",
  "Lævateinn Dragon Attack Form Evolved",
  "Draconir, Knuckle Dragon Evolved",
  "Hammer Dragonewt Evolved",
  "Dragon Summoner Evolved",
  "Masquerade Ghost Evolved",
  "Baccherus, Peppy Ghostie Evolved",
  "Trombone Devil Evolved",
  "Mischievous Zombie Evolved",
  "Diamond Master Evolved",
  "Wingy Chirpy Gemstone Evolved",
  "Ruby Falcon Evolved",
  "Pinion Prince Evolved",
  "Alice, Wonderland Explorer Evolved",
  "Garuel, Seraphic Leo Evolved",
  "Humpty Dumpty Evolved",
  "Harbringer of the Night Evolved",

  "Silence Suzuka Evolved",
  "Tokai Teio Evolved",
  "Agnes Tachyon Evolved",
  "Special Week Evolved",
  "Maruzensky Evolved",
  "Mejiro McQueen Evolved",
  "Carrot",

  "Crystalia Tia Evolved",
  "Grand Archer Seiwyn Evolved",
  "Crystalia Lily Evolved",
  "Forest Gigas Evolved",
  "Albert Levin Saber Evolved",
  "Leonidas Evolved",
  "Jeno, Levin Vanguard Evolved",
  "Avant Blader Evolved",
  "Daria Dimensional Witch Evolved",
  "Anne, Belle oF Mysteria Evolved",
  "Remi & Rami, Witchy Duo Evolved",
  "Craig, Wizard of Mysteria Evolved",
  "Imperial Dragoon Evolved",
  "Neptune Evolved",
  "Siegfried Evolved",
  "Dragontamer Evolved",
  "Soul Dealer Evolved",
  "Azazel Evolved",
  "Veight, Vampire Noble Evolved",
  "Demonic Hedonist Evolved",
  "Heavenly Aegis Evolved",
  "Kaguya Evolved",
  "Radiance Angel Evolved",
  "Soul Collector Evolved",
  "Bahamut Evolved",
  "Archangel Reina Evolved",
  "Unicorn Dancer Unicorn Evolved",
  "Sektor Evolved",

  "Priest of the Cudgel Evolved",
  "Ardent Nun Evolved",
  "Guardian Nun Evolved",
  "Playful Necromancer Evolved",
  "Lesser Mummy Evolved",
  "Lilith Evolved",
  "Dragon Warrior Evolved",
  "Roc Evolved",
  "Dragonrider Evolved",
  "Demonflame Mage Evolved",
  "Penguin Wizard Evolved",
  "Sammy Wizard's Apprentice Evolved",
  "Floral Fencer Evolved",
  "Oathless Knight Evolved",
  "Quickblader Evolved",
  "Rose Gardener Evolved",
  "Treant Evolved",
  "Water Fairy Evolved",
  "Ancient Elf Evolved",
  "Rhinoceroach Evolved",
  "Elven Princess Mage Evolved",
  "Archer Evolved",
  "Sea Queen Otohime Evolved",
  "Shadowed Assassin Evolved",
  "Maid Leader Evolved",
  "Fervid Soldier Evolved",
  "Merlin Evolved",
  "Ancient Alchemist Evolved",
  "Spectral Wizard Evolved",
  "Crafty Warlock Evolved",
  "Dark Dragoon Forte Evolved U",
  "Shapeshifting Mage Evolved",
  "Shenlong Evolved",
  "Ivory Dragon Evolved",
  "Cerberus Evolved",
  "Righteous Devil Evolved",
  "Crazed Executioner Evolved",
  "Wardrobe Raider Evolved",
  "Jeanne d'Arc Evolved",
  "Arch Priestess Laelia Evolved",
  "Prism Priestess Evolved",
  "Mainyu Evolved",
  "Lucifer Evolved",
  "Urd Evolved",
  "Bellringer Angel Evolved",
  "Goblinmount Demon Evolved",
  "Goblin Evolved",
  "Goliath Evolved",
  "Healing Angel Evolved",
];

export const set9Evo = [
  "White Vanara Evolved",
  "Paula, Gentle Warmth",
  "Storied Falconer Evolved",
  "Grasshopper Conductor Evolved",

  "Celia, Hope's Strategist",
  "Innocent Princess Prim Evolved",
  "Dario, Demon Count Evolved",
  "Axe Princess Evolved",

  "Ceridwen, Eternity Hunter Evolved",
  "Mysterian Whitewyrm",
  "Bergent, Onion Patchmaster Evolved",
  "Witch of Foresight Evolved",
  "Grea, Mysterian Dragoness Evolved",
  "Mysterian Summoner Vayle Evolved",

  "Jerva of Draconic Mail Evolved",
  "Virtuous Lindworm",
  "Dragoncleaver Roy Evolved",
  "Heroic Dragonslayer Evolved",
  "Romelia, Lightning Striker Evolved",
  "Katakura Kojuro Evolved",

  "Kind Queen Vania",
  "Oldblood King Evolved",
  "Big Soul Hunter Evolved",
  "Orator of the Bones Evolved",

  "Jeanne, Beacon of Salvation Evolved",
  "Cerynelan Lighthind",
  "Jeweled Priestess Evolved",
  "Lycaon Evolved",

  "Marduk Evolved",
  "Paradise Vanguard Evolved",
  "Suttungr Evolved",
  "Valkyrie of Chaos Evolved",
];

export const set8Evo = [
  "Orchis, Resolute Puppet",
  "Zwei, Murderous Puppet Evolved",
  "Michelle, the Mind Reader Evolved",
  "Insane Dark Elf Evolved",

  "Dionne, Dancing Blade Evolved",
  "Roland the Incorruptible Evolved",
  "Azord, Duke of the Mists Evolved",
  "Mana Pistol Merc Evolved",

  "Sweet-Tooth Medusa Evolved",
  "Unbodied Witch Evolved",
  "Elusa, Magic Wunderkind Evolved",
  "Rabbit Mage Evolved",

  "Azi Dahaka Evolved",
  "Annerose Evolved",
  "Elios, Loyal Dragoon Evolved",
  "Geovore Evolved",

  "Crimson Rose Queen Evolved",
  "Tartarus, the Tormentor Evolved",
  "Chris Pumpkinhead Evolved",
  "Marian the Mummy Evolved",

  "Godsworn Alexiel Evolved",
  "Eidolon of Madness Evolved",
  "Collete, Holy Gunner Evolved",
  "Temple Windbear Evolved",

  "Alterplane Arbiter Evolved",
  "Sahaquiel Evolved",
  "Reina, Evolution's Herald Evolved",
  "High Enchantress Evolved",
];

export const set7Evo = [
  "Ladica, the Stoneclaw Evolved",
  "Setus, the Beastblade Evolved",
  "Blossom Spirit Evolved",
  "Forest Hermit Evolved",

  "Bayleon, Sovereign Light Evolved",
  "Leod, the Crescent Blade Evolved",
  "Troya, Thunder of Hagelberg Evolved",
  "Dauntless Commander Evolved",

  "Tetra, Sapphire Rebel Evolved",
  "Eleanor, Cosmic Flower Evolved",
  "Displacer Bot Evolved",
  "Magiblade Witch Evolved",

  "Valdain, Cursed Shadow Evolved",
  "Marion, Elegant Dragonewt Evolved",
  "Hoarfrost Triceratops Evolved",
  "Doting Dragoneer Evolved",

  "Mono, Garnet Rebel Evolved",
  "Doublame, Duke and Dame Evolved",
  "Nicola, Forbidden Strength Evolved",
  "Berserk Demon Evolved",

  "Limonia, Flawed Saint Evolved",
  "Marione, Light of Balance Evolved",
  "Robofalcon Evolved",
  "Dark Bishop Evolved",

  "Viridia Magna Evolved",
  "Maisha, Hero of Purgation Evolved",
  "Robogoblin Evolved",
  "Aldis, Trendsetting Seraph Evolved",
];

export const setIDOLEvo = [
  "Momoka Sakurai Evolved",
  "Akiha Ikebukuro Evolved",
  "Nene Kurihara Evolved",

  "Yukimi Sajo Evolved",
  "Kako Takafuji Evolved",
  "Seira Mizuki Evolved",

  "Suzuho Ueda Evolved",
  "Miria Akagi Evolved",
  "Kumiko Matsuyama Evolved",

  "Aiko Takamori Evolved",
  "Yuzu Kitami Evolved",
  "Yumi Aiba Evolved",
  "Kana Imai Evolved",

  "Kyoko Igarashi Evolved",
  "Uzuki Shimamura Evolved",
  "Karen Hojo Evolved",
  "Miho Kohinata Evolved",

  "Shiki Ichinose Evolved",
  "Kanade Hayami Evolved",
  "Hina Araki Evolved",
  "Rika Jougasaki Evolved",

  "Yui Ohtsuki Evolved",
  "Tsukasa Kiryu Evolved",
  "Yuka Nakano Evolved",
  "Noa Takamine Evolved",

  "Ranko Kanzaki Evolved",
  "Takumi Mukai Evolved",
  "Aki Yamato Evolved",
  "Syoko Hoshi Evolved",

  "Shin Sato Evolved",
  "Nana Abe Evolved",
  "Haru Yuuki Evolved",
  "Layla Evolved",
];

export const set6Evo = [
  "Lymaga, Forest Champion Evolved",
  "Wildwood Matriarch Evolved",
  "Woodland Cleaver Evolved",
  "Mallet Monkey Evolved",

  "Ralmia, Sonic Racer Evolved",
  "Hero of Antiquity Evolved",
  "Quickdraw Maven Evolved",
  "Samurai Outlaw Evolved",

  "Mysteria, Magic Founder Evolved",
  "Curse Crafter Evolved",
  "Demoncaller Evolved",
  "Charming Gentlemouse Evolved",

  "Garyu, Surpreme Dragonkin Evolved",
  "Wyrm God of the Skies Evolved",
  "Ice Dancing Dragonewt Evolved",
  "Dragonblader Evolved",

  "Ginsetsu, Great Fox Evolved",
  "Shuten-Doji Evolved",
  "Cougar Pelt Warrior Evolved",
  "Zashiki-Warashi Evolved",

  "Karula, Arts Master Evolved",
  "Phantom Blade Wielder Evolved",
  "Holy Lancer Evolved",
  "Barrage Brawler Evolved",

  "Badb Catha Evolved",
  "Mithra, Daybreak Diety Evolved",
  "Chaht, Ringside Announcer Evolved",
  "Bazooka Goblins Evolved",
];
export const set5Evo = [
  "Izudia, Omen of Unkilling Evolved",
  "Apostle of Unkilling Evolved",
  "Noah, Vengeful Puppeteer Evolved",
  "Flower Doll Evolved",

  "Octrice, Omen of Usurpation Evolved",
  "Apostle of Usurpation Evolved",
  "Geno, Machine Artisan Evolved",
  "Captain Meteo Evolved",

  "Raio, Omen of Truth Evolved",
  "Apostle of Truth Evolved",
  "Iron Staff Mechanic Evolved",
  "Servant of Destruction Evolved",

  "Galmieux, Omen of Disdain Evolved",
  "Apostle of Disdain Evolved",
  "Cursed Stone Evolved",
  "Airship Whale Evolved",

  "Rulenye, Omen of Silence Evolved",
  "Apostle of Silence Evolved",
  "Masked Puppet Evolved",
  "Servant of Lust Evolved",

  "Marwynn, Omen of Repose Evolved",
  "Apostle of Repose Evolved",
  "Unidentified Subject Evolved",
  "Demon's Epitaph Evolved",

  "Mjerrabaine, Omen of One Evolved",
  "Apostle of Craving Evolved",
  "Rosa, Mech Wing Maiden Evolved",
  "Cat Cannoneer Evolved",
];

export const set4Evo = [
  "Deepwood Anomaly Evolved",
  "King Elephant Evolved",
  "Sukuna, Brave and Small Evolved",
  "Fita the Gentle Elf Evolved",
  "Mars, Silent Flame General Evolved",
  "Barbarossa Evolved",
  "Shrouded Assassin Evolved",
  "Tristan of the Round Table Evolved",
  "Wordwielder Ginger Evolved",
  "Europa Evolved",
  "Magic Illusionist Evolved",
  "Dazzling Healer Evolved",
  "Python Evolved",
  "Lævateinn Dragon, Defense Form Evolved",
  "Lævateinn Dragon, Blast Form Evolved",
  "Star Phoenix Evolved",
  "Cetus Evolved",
  "Hippocampus Evolved",
  "Howling Demon Evolved",
  "Stheno Evolved",
  "Fenrir Evolved",
  "Frogbat Evolved",
  "Dark Jeanne Evolved",
  "Zoe, Princess of Goldenia Evolved",
  "Star Priestess Evolved",
  "Mist Shaman Evolved",
  "Israfil Evolved",
  "Grimnir, War Cyclone Evolved",
  "Goblin Princess Evolved",
  "Owlcat Evolved",
];

export const set3Evo = [
  "Cosmos Fang Evolved",
  "Slade Blossoming Wolf Evolved",
  "Gerbera Bear Evolved",
  "Tweedle Dum, Tweedle Dee Evolved",
  "Valiant Fencer Evolved",
  "Amerro, Spear Knight Evolved",
  "Mach Knight Evolved",
  "Bladed Hedgehog Evolved",
  "Mystic King Evolved",
  "Milady, Mystic Queen Evolved",
  "Magical Knight Evolved",
  "Witch of Sweets Evolved",
  "Lævateinn Dragon Evolved",
  "Lævateinn Dragon Attack Form Evolved",
  "Draconir, Knuckle Dragon Evolved",
  "Hammer Dragonewt Evolved",
  "Dragon Summoner Evolved",
  "Masquerade Ghost Evolved",
  "Baccherus, Peppy Ghostie Evolved",
  "Trombone Devil Evolved",
  "Mischievous Zombie Evolved",
  "Diamond Master Evolved",
  "Wingy Chirpy Gemstone Evolved",
  "Ruby Falcon Evolved",
  "Pinion Prince Evolved",
  "Alice, Wonderland Explorer Evolved",
  "Garuel, Seraphic Leo Evolved",
  "Humpty Dumpty Evolved",
  "Harbringer of the Night Evolved",
];

export const setUMAEvo = [
  "Silence Suzuka Evolved",
  "Tokai Teio Evolved",
  "Agnes Tachyon Evolved",
  "Special Week Evolved",
  "Maruzensky Evolved",
  "Mejiro McQueen Evolved",
  "Carrot",
];

export const set2Evo = [
  "Crystalia Tia Evolved",
  "Grand Archer Seiwyn Evolved",
  "Crystalia Lily Evolved",
  "Forest Gigas Evolved",
  "Albert Levin Saber Evolved",
  "Leonidas Evolved",
  "Jeno, Levin Vanguard Evolved",
  "Avant Blader Evolved",
  "Daria Dimensional Witch Evolved",
  "Anne, Belle oF Mysteria Evolved",
  "Remi & Rami, Witchy Duo Evolved",
  "Craig, Wizard of Mysteria Evolved",
  "Imperial Dragoon Evolved",
  "Neptune Evolved",
  "Siegfried Evolved",
  "Dragontamer Evolved",
  "Soul Dealer Evolved",
  "Azazel Evolved",
  "Veight, Vampire Noble Evolved",
  "Demonic Hedonist Evolved",
  "Heavenly Aegis Evolved",
  "Kaguya Evolved",
  "Radiance Angel Evolved",
  "Soul Collector Evolved",
  "Bahamut Evolved",
  "Archangel Reina Evolved",
  "Unicorn Dancer Unicorn Evolved",
  "Sektor Evolved",
];

export const set1Evo = [
  "Priest of the Cudgel Evolved",
  "Ardent Nun Evolved",
  "Guardian Nun Evolved",
  "Playful Necromancer Evolved",
  "Lesser Mummy Evolved",
  "Lilith Evolved",
  "Dragon Warrior Evolved",
  "Roc Evolved",
  "Dragonrider Evolved",
  "Demonflame Mage Evolved",
  "Penguin Wizard Evolved",
  "Sammy Wizard's Apprentice Evolved",
  "Floral Fencer Evolved",
  "Oathless Knight Evolved",
  "Quickblader Evolved",
  "Rose Gardener Evolved",
  "Treant Evolved",
  "Water Fairy Evolved",
  "Ancient Elf Evolved",
  "Rhinoceroach Evolved",
  "Elven Princess Mage Evolved",
  "Archer Evolved",
  "Sea Queen Otohime Evolved",
  "Shadowed Assassin Evolved",
  "Maid Leader Evolved",
  "Fervid Soldier Evolved",
  "Merlin Evolved",
  "Ancient Alchemist Evolved",
  "Spectral Wizard Evolved",
  "Crafty Warlock Evolved",
  "Dark Dragoon Forte Evolved U",
  "Shapeshifting Mage Evolved",
  "Shenlong Evolved",
  "Ivory Dragon Evolved",
  "Cerberus Evolved",
  "Righteous Devil Evolved",
  "Crazed Executioner Evolved",
  "Wardrobe Raider Evolved",
  "Jeanne d'Arc Evolved",
  "Arch Priestess Laelia Evolved",
  "Prism Priestess Evolved",
  "Mainyu Evolved",
  "Lucifer Evolved",
  "Urd Evolved",
  "Bellringer Angel Evolved",
  "Goblinmount Demon Evolved",
  "Goblin Evolved",
  "Goliath Evolved",
  "Healing Angel Evolved",
];

export const forestEvo = [
  "White Vanara Evolved",
  "Paula, Gentle Warmth",
  "Storied Falconer Evolved",
  "Grasshopper Conductor Evolved",

  "Orchis, Resolute Puppet",
  "Zwei, Murderous Puppet Evolved",
  "Michelle, the Mind Reader Evolved",
  "Insane Dark Elf Evolved",

  "Ladica, the Stoneclaw Evolved",
  "Setus, the Beastblade Evolved",
  "Blossom Spirit Evolved",
  "Forest Hermit Evolved",

  "Momoka Sakurai Evolved",
  "Suzuho Ueda Evolved",
  "Aiko Takamori Evolved",
  "Yuzu Kitami Evolved",
  "Yumi Aiba Evolved",
  "Kana Imai Evolved",

  "Lymaga, Forest Champion Evolved",
  "Wildwood Matriarch Evolved",
  "Woodland Cleaver Evolved",
  "Mallet Monkey Evolved",
  "Izudia, Omen of Unkilling Evolved",
  "Apostle of Unkilling Evolved",
  "Noah, Vengeful Puppeteer Evolved",
  "Flower Doll Evolved",
  "Deepwood Anomaly Evolved",
  "King Elephant Evolved",
  "Sukuna, Brave and Small Evolved",
  "Fita the Gentle Elf Evolved",
  "Cosmos Fang Evolved",
  "Slade Blossoming Wolf Evolved",
  "Gerbera Bear Evolved",
  "Tweedle Dum, Tweedle Dee Evolved",
  "Silence Suzuka Evolved",
  "Crystalia Tia Evolved",
  "Grand Archer Seiwyn Evolved",
  "Crystalia Lily Evolved",
  "Forest Gigas Evolved",
  "Rose Gardener Evolved",
  "Treant Evolved",
  "Water Fairy Evolved",
  "Ancient Elf Evolved",
  "Rhinoceroach Evolved",
  "Elven Princess Mage Evolved",
  "Archer Evolved",
];

export const swordEvo = [
  "Celia, Hope's Strategist",
  "Innocent Princess Prim Evolved",
  "Dario, Demon Count Evolved",
  "Axe Princess Evolved",

  "Dionne, Dancing Blade Evolved",
  "Roland the Incorruptible Evolved",
  "Azord, Duke of the Mists Evolved",
  "Mana Pistol Merc Evolved",

  "Bayleon, Sovereign Light Evolved",
  "Leod, the Crescent Blade Evolved",
  "Troya, Thunder of Hagelberg Evolved",
  "Dauntless Commander Evolved",

  "Miria Akagi Evolved",
  "Kumiko Matsuyama Evolved",
  "Kyoko Igarashi Evolved",
  "Uzuki Shimamura Evolved",
  "Karen Hojo Evolved",
  "Miho Kohinata Evolved",

  "Ralmia, Sonic Racer Evolved",
  "Hero of Antiquity Evolved",
  "Quickdraw Maven Evolved",
  "Samurai Outlaw Evolved",
  "Octrice, Omen of Usurpation Evolved",
  "Apostle of Usurpation Evolved",
  "Geno, Machine Artisan Evolved",
  "Captain Meteo Evolved",
  "Mars, Silent Flame General Evolved",
  "Barbarossa Evolved",
  "Shrouded Assassin Evolved",
  "Tristan of the Round Table Evolved",
  "Valiant Fencer Evolved",
  "Amerro, Spear Knight Evolved",
  "Mach Knight Evolved",
  "Bladed Hedgehog Evolved",
  "Tokai Teio Evolved",
  "Albert Levin Saber Evolved",
  "Leonidas Evolved",
  "Jeno, Levin Vanguard Evolved",
  "Avant Blader Evolved",
  "Floral Fencer Evolved",
  "Oathless Knight Evolved",
  "Quickblader Evolved",
  "Sea Queen Otohime Evolved",
  "Shadowed Assassin Evolved",
  "Maid Leader Evolved",
  "Fervid Soldier Evolved",
];

export const runeEvo = [
  "Ceridwen, Eternity Hunter Evolved",
  "Mysterian Whitewyrm",
  "Bergent, Onion Patchmaster Evolved",
  "Witch of Foresight Evolved",
  "Grea, Mysterian Dragoness Evolved",
  "Mysterian Summoner Vayle Evolved",

  "Sweet-Tooth Medusa Evolved",
  "Unbodied Witch Evolved",
  "Elusa, Magic Wunderkind Evolved",
  "Rabbit Mage Evolved",

  "Tetra, Sapphire Rebel Evolved",
  "Eleanor, Cosmic Flower Evolved",
  "Displacer Bot Evolved",
  "Magiblade Witch Evolved",

  "Akiha Ikebukuro Evolved",
  "Seira Mizuki Evolved",
  "Shiki Ichinose Evolved",
  "Kanade Hayami Evolved",
  "Hina Araki Evolved",
  "Rika Jougasaki Evolved",

  "Mysteria, Magic Founder Evolved",
  "Curse Crafter Evolved",
  "Demoncaller Evolved",
  "Charming Gentlemouse Evolved",
  "Raio, Omen of Truth Evolved",
  "Apostle of Truth Evolved",
  "Iron Staff Mechanic Evolved",
  "Servant of Destruction Evolved",
  "Wordwielder Ginger Evolved",
  "Europa Evolved",
  "Magic Illusionist Evolved",
  "Dazzling Healer Evolved",
  "Mystic King Evolved",
  "Milady, Mystic Queen Evolved",
  "Magical Knight Evolved",
  "Witch of Sweets Evolved",
  "Agnes Tachyon Evolved",
  "Daria Dimensional Witch Evolved",
  "Anne, Belle oF Mysteria Evolved",
  "Remi & Rami, Witchy Duo Evolved",
  "Craig, Wizard of Mysteria Evolved",
  "Demonflame Mage Evolved",
  "Penguin Wizard Evolved",
  "Sammy Wizard's Apprentice Evolved",
  "Merlin Evolved",
  "Ancient Alchemist Evolved",
  "Spectral Wizard Evolved",
  "Crafty Warlock Evolved",
];

export const dragonEvo = [
  "Jerva of Draconic Mail Evolved",
  "Virtuous Lindworm",
  "Dragoncleaver Roy Evolved",
  "Heroic Dragonslayer Evolved",
  "Romelia, Lightning Striker Evolved",
  "Katakura Kojuro Evolved",

  "Azi Dahaka Evolved",
  "Annerose Evolved",
  "Elios, Loyal Dragoon Evolved",
  "Geovore Evolved",

  "Valdain, Cursed Shadow Evolved",
  "Marion, Elegant Dragonewt Evolved",
  "Hoarfrost Triceratops Evolved",
  "Doting Dragoneer Evolved",

  "Nene Kurihara Evolved",
  "Yui Ohtsuki Evolved",
  "Tsukasa Kiryu Evolved",
  "Yuka Nakano Evolved",
  "Noa Takamine Evolved",

  "Garyu, Surpreme Dragonkin Evolved",
  "Wyrm God of the Skies Evolved",
  "Ice Dancing Dragonewt Evolved",
  "Dragonblader Evolved",
  "Galmieux, Omen of Disdain Evolved",
  "Apostle of Disdain Evolved",
  "Cursed Stone Evolved",
  "Airship Whale Evolved",
  "Python Evolved",
  "Lævateinn Dragon, Defense Form Evolved",
  "Lævateinn Dragon, Blast Form Evolved",
  "Star Phoenix Evolved",
  "Cetus Evolved",
  "Hippocampus Evolved",
  "Lævateinn Dragon Evolved",
  "Lævateinn Dragon Attack Form Evolved",
  "Draconir, Knuckle Dragon Evolved",
  "Hammer Dragonewt Evolved",
  "Dragon Summoner Evolved",
  "Special Week Evolved",
  "Imperial Dragoon Evolved",
  "Neptune Evolved",
  "Siegfried Evolved",
  "Dragontamer Evolved",
  "Dragon Warrior Evolved",
  "Roc Evolved",
  "Dragonrider Evolved",
  "Dark Dragoon Forte Evolved U",
  "Shapeshifting Mage Evolved",
  "Shenlong Evolved",
  "Ivory Dragon Evolved",
];

export const abyssEvo = [
  "Kind Queen Vania",
  "Oldblood King Evolved",
  "Big Soul Hunter Evolved",
  "Orator of the Bones Evolved",

  "Crimson Rose Queen Evolved",
  "Tartarus, the Tormentor Evolved",
  "Chris Pumpkinhead Evolved",
  "Marian the Mummy Evolved",

  "Mono, Garnet Rebel Evolved",
  "Doublame, Duke and Dame Evolved",
  "Nicola, Forbidden Strength Evolved",
  "Berserk Demon Evolved",

  "Yukimi Sajo Evolved",
  "Ranko Kanzaki Evolved",
  "Takumi Mukai Evolved",
  "Aki Yamato Evolved",
  "Syoko Hoshi Evolved",

  "Ginsetsu, Great Fox Evolved",
  "Shuten-Doji Evolved",
  "Cougar Pelt Warrior Evolved",
  "Zashiki-Warashi Evolved",
  "Rulenye, Omen of Silence Evolved",
  "Apostle of Silence Evolved",
  "Masked Puppet Evolved",
  "Servant of Lust Evolved",
  "Howling Demon Evolved",
  "Stheno Evolved",
  "Fenrir Evolved",
  "Frogbat Evolved",
  "Masquerade Ghost Evolved",
  "Baccherus, Peppy Ghostie Evolved",
  "Trombone Devil Evolved",
  "Mischievous Zombie Evolved",
  "Maruzensky Evolved",
  "Soul Dealer Evolved",
  "Azazel Evolved",
  "Veight, Vampire Noble Evolved",
  "Demonic Hedonist Evolved",
  "Playful Necromancer Evolved",
  "Lesser Mummy Evolved",
  "Lilith Evolved",
  "Cerberus Evolved",
  "Righteous Devil Evolved",
  "Crazed Executioner Evolved",
  "Wardrobe Raider Evolved",
];

export const havenEvo = [
  "Jeanne, Beacon of Salvation Evolved",
  "Cerynelan Lighthind",
  "Jeweled Priestess Evolved",
  "Lycaon Evolved",

  "Godsworn Alexiel Evolved",
  "Eidolon of Madness Evolved",
  "Collete, Holy Gunner Evolved",
  "Temple Windbear Evolved",

  "Limonia, Flawed Saint Evolved",
  "Marione, Light of Balance Evolved",
  "Robofalcon Evolved",
  "Dark Bishop Evolved",

  "Kako Takafuji Evolved",
  "Shin Sato Evolved",
  "Nana Abe Evolved",
  "Haru Yuuki Evolved",
  "Layla Evolved",

  "Karula, Arts Master Evolved",
  "Phantom Blade Wielder Evolved",
  "Holy Lancer Evolved",
  "Barrage Brawler Evolved",
  "Marwynn, Omen of Repose Evolved",
  "Apostle of Repose Evolved",
  "Unidentified Subject Evolved",
  "Demon's Epitaph Evolved",
  "Dark Jeanne Evolved",
  "Zoe, Princess of Goldenia Evolved",
  "Star Priestess Evolved",
  "Mist Shaman Evolved",
  "Diamond Master Evolved",
  "Wingy Chirpy Gemstone Evolved",
  "Ruby Falcon Evolved",
  "Pinion Prince Evolved",
  "Mejiro McQueen Evolved",
  "Heavenly Aegis Evolved",
  "Kaguya Evolved",
  "Radiance Angel Evolved",
  "Soul Collector Evolved",
  "Priest of the Cudgel Evolved",
  "Ardent Nun Evolved",
  "Guardian Nun Evolved",
  "Jeanne d'Arc Evolved",
  "Arch Priestess Laelia Evolved",
  "Prism Priestess Evolved",
  "Mainyu Evolved",
];

export const neutralEvo = [
  "Marduk Evolved",
  "Paradise Vanguard Evolved",
  "Suttungr Evolved",
  "Valkyrie of Chaos Evolved",

  "Alterplane Arbiter Evolved",
  "Sahaquiel Evolved",
  "Reina, Evolution's Herald Evolved",
  "High Enchantress Evolved",

  "Viridia Magna Evolved",
  "Maisha, Hero of Purgation Evolved",
  "Robogoblin Evolved",
  "Aldis, Trendsetting Seraph Evolved",

  "Badb Catha Evolved",
  "Mithra, Daybreak Diety Evolved",
  "Chaht, Ringside Announcer Evolved",
  "Bazooka Goblins Evolved",

  "Mjerrabaine, Omen of One Evolved",
  "Apostle of Craving Evolved",
  "Rosa, Mech Wing Maiden Evolved",
  "Cat Cannoneer Evolved",

  "Israfil Evolved",
  "Grimnir, War Cyclone Evolved",
  "Goblin Princess Evolved",
  "Owlcat Evolved",

  "Alice, Wonderland Explorer Evolved",
  "Garuel, Seraphic Leo Evolved",
  "Humpty Dumpty Evolved",
  "Harbringer of the Night Evolved",

  "Carrot",

  "Bahamut Evolved",
  "Archangel Reina Evolved",
  "Unicorn Dancer Unicorn Evolved",

  "Sektor Evolved",
  "Lucifer Evolved",
  "Urd Evolved",
  "Bellringer Angel Evolved",
  "Goblinmount Demon Evolved",
  "Goblin Evolved",
  "Goliath Evolved",
  "Healing Angel Evolved",
];
