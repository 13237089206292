export const cardImage = (cardName) => {
  switch (cardName) {
    case "Yggdrasil":
      return "../textures/bp09-u01.png";
    case "White Vanara":
      return "../textures/bp09-002.png";
    case "Paula, Icy Warmth":
      return "../textures/bp09-004.png";
    case "Greenglen Axeman":
      return "../textures/bp09-006.png";
    case "Wrath of Nature":
      return "../textures/bp09-007.png";
    case "Storied Falconer":
      return "../textures/bp09-008.png";
    case "Owl Man":
      return "../textures/bp09-010.png";
    case "Blessings of Creation":
      return "../textures/bp09-011.png";
    case "Grasshopper Conductor":
      return "../textures/bp09-012.png";
    case "Elf General":
      return "../textures/bp09-014.png";
    case "Lila, Arborist":
      return "../textures/bp09-015.png";
    case "Substitution":
      return "../textures/bp09-016.png";
    case "Flower of Fairies":
      return "../textures/bp09-017.png";
    case "Celia, Sky Commander":
      return "../textures/bp09-018.png";
    case "Spartacus":
      return "../textures/bp09-u02.png";
    case "Innocent Princess Prim":
      return "../textures/bp09-021.png";
    case "Nonja, Silent Maid":
      return "../textures/bp09-023.png";
    case "Chromatic Duel":
      return "../textures/PR-223EN.png";
    case "Dario, Demon Count":
      return "../textures/bp09-025.png";
    case "Queen Hemera the White":
      return "../textures/bp09-027.png";
    case "Queen Magnus the Black":
      return "../textures/bp09-028.png";
    case "Axe Princess":
      return "../textures/bp09-029.png";
    case "Master Samurai":
      return "../textures/bp09-031.png";
    case "Savage Swordsman":
      return "../textures/bp09-032.png";
    case "Tycoon":
      return "../textures/bp09-033.png";
    case "Frontline Ramparts":
      return "../textures/bp09-034.png";
    case "Ceridwen, Eternity Hunter":
      return "../textures/bp09-035.png";
    case "Truthseeker Faust":
      return "../textures/bp09-u03.png";
    case "Mysterian Wyrmist":
      return "../textures/bp09-038.png";
    case "Snowman King":
      return "../textures/bp09-040.png";
    case "Absolute Zeroblade":
      return "../textures/bp09-041.png";
    case "Bergent, Onion Patchmaster":
      return "../textures/bp09-042.png";
    case "Palia, Student Teacher":
      return "../textures/bp09-044.png";
    case "Summoning Drills":
      return "../textures/bp09-045.png";
    case "Witch of Foresight":
      return "../textures/bp09-046.png";
    case "Beastfaced Mage":
      return "../textures/bp09-048.png";
    case "Onion Patch":
      return "../textures/bp09-049.png";
    case "Tico, Mysterian Spellnerd":
      return "../textures/bp09-050.png";
    case "Staff of Whirlwinds":
      return "../textures/bp09-051.png";
    case "Anne, Mysterian Prodigy":
      return "../textures/dsd01a-u01.png";
    case "Grea, Mysterian Dragoness":
      return "../textures/dsd01a-002.png";
    case "Owen, Knight of Mysteria":
      return "../textures/dsd01a-006.png";
    case "Anne's Sorcery":
      return "../textures/dsd01a-008.png";
    case "Grea's Ember":
      return "../textures/dsd01a-009.png";
    case "Mysterian Summoner Vayle":
      return "../textures/dsd01a-010.png";
    case "Mysterian Knowledge":
      return "../textures/dsd01a-014.png";
    case "Mr. Bertrand, Magic Mentor":
      return "../textures/dsd01a-015.png";
    case "Jerva of Draconic Mail":
      return "../textures/bp09-052.png";
    case "Zirnitra, Dragon's Flame":
      return "../textures/bp09-054.png";
    case "Lindworm":
      return "../textures/bp09-055.png";
    case "Dragonplate Warrior":
      return "../textures/bp09-057.png";
    case "Force of the Dragonewt":
      return "../textures/bp09-058.png";
    case "Dragoncleaver Roy":
      return "../textures/bp09-059.png";
    case "Galua of Two Breaths":
      return "../textures/bp09-061.png";
    case "Waters of the Orca":
      return "../textures/bp09-062.png";
    case "Heroic Dragonslayer":
      return "../textures/bp09-063.png";
    case "Dragonclad Blademaster":
      return "../textures/bp09-065.png";
    case "Drakewing Assassin":
      return "../textures/bp09-066.png";
    case "Twilight Dragoon Coda":
      return "../textures/bp09-067.png";
    case "Dragon's Handspur":
      return "../textures/bp09-068.png";
    case "Romelia, Lightning Striker":
      return "../textures/dsd01b-001.png";
    case "Masamune, Raging Dragon":
      return "../textures/dsd01b-003.png";
    case "Poseidon":
      return "../textures/dsd01b-006.png";
    case "Pure-Voiced Dragoon":
      return "../textures/dsd01b-007.png";
    case "Katakura Kojuro":
      return "../textures/dsd01b-010.png";
    case "Dragoon Scyther":
      return "../textures/dsd01b-012.png";
    case "Gargouille":
      return "../textures/dsd01b-013.png";
    case "Hypersonic Dragonewt":
      return "../textures/dsd01b-016.png";
    case "Vania, Nightshade Vampire":
      return "../textures/bp09-069.png";
    case "Arcus, Ghostly Manager":
      return "../textures/bp09-u05.png";
    case "Oldblood King":
      return "../textures/bp09-072.png";
    case "Darkfeast Bat":
      return "../textures/bp09-074.png";
    case "Gift for Bloodkin":
      return "../textures/bp09-075.png";
    case "Big Soul Hunter":
      return "../textures/bp09-076.png";
    case "Raven, Eventide Vampire":
      return "../textures/bp09-078.png";
    case "Blood Moon":
      return "../textures/bp09-079.png";
    case "Orator of the Bones":
      return "../textures/bp09-080.png";
    case "Raven, Noontide Vampire":
      return "../textures/bp09-082.png";
    case "Raven, Midnight Vampire":
      return "../textures/bp09-083.png";
    case "Death the Nyctophile":
      return "../textures/bp09-084.png";
    case "Poltergeist":
      return "../textures/bp09-085.png";
    case "Jeanne, Beacon of Salvation":
      return "../textures/bp09-086.png";
    case "Tutankhamun":
      return "../textures/bp09-088.png";
    case "Cerynelan Hind":
      return "../textures/bp09-089.png";
    case "Heavenly Knight":
      return "../textures/bp09-091.png";
    case "Tenko's Shrine":
      return "../textures/bp09-092.png";
    case "Jeweled Priestess":
      return "../textures/bp09-093.png";
    case "Whitefang Temple":
      return "../textures/bp09-095.png";
    case "Opposing Statues":
      return "../textures/bp09-096.png";
    case "Lycaon":
      return "../textures/bp09-097.png";
    case "Howl Fowl of Ivory":
      return "../textures/bp09-099.png";
    case "Hexed Fowl of Ebon":
      return "../textures/bp09-100.png";
    case "Deathscythe Nun":
      return "../textures/bp09-101.png";
    case "Moriae Encomium":
      return "../textures/bp09-102.png";
    case "Marduk":
      return "../textures/bp09-103.png";
    case "Moon and Sun":
      return "../textures/bp09-105.png";
    case "Paradise Vanguard":
      return "../textures/bp09-106.png";
    case "Amaterasu":
      return "../textures/bp09-108.png";
    case "Tsukuyomi":
      return "../textures/bp09-109.png";
    case "Suttungr":
      return "../textures/bp09-110.png";
    case "Oceanus":
      return "../textures/bp09-112.png";
    case "Divine Retribution":
      return "../textures/bp09-113.png";
    case "Valkyrie of Chaos":
      return "../textures/bp09-114.png";
    case "Valkyrie of Order":
      return "../textures/bp09-116.png";
    case "Fount of Angels":
      return "../textures/bp09-117.png";
    case "Forest Oracle Pascale":
      return "../textures/BP08-001EN.png";
    case "Orchis, Puppet Girl":
      return "../textures/BP08-U01EN.png";
    case "Zwei, Murderous Puppet":
      return "../textures/BP08-004EN.png";
    case "Lycoris, Poisoner Princess":
      return "../textures/BP08-006EN.png";
    case "Lina & Lena, Twin Souls":
      return "../textures/BP08-007EN.png";
    case "Michelle, the Mind Reader":
      return "../textures/BP08-008EN.png";
    case "Liam, Master of Puppets":
      return "../textures/BP08-010EN.png";
    case "Heartless Battle":
      return "../textures/BP08-011EN.png";
    case "Junk":
      return "../textures/BP08-012EN.png";
    case "Insane Dark Elf":
      return "../textures/BP08-013EN.png";
    case "Zealot of Unkilling":
      return "../textures/BP08-015EN.png";
    case "Knower of History":
      return "../textures/BP08-016EN.png";
    case "Ward of Unkilling":
      return "../textures/BP08-017EN.png";
    case "Aether of the Warrior Wing":
      return "../textures/BP08-018EN.png";
    case "Dionne, Dancing Blade":
      return "../textures/BP08-019EN.png";
    case "Roland the Incorruptible":
      return "../textures/BP08-021EN.png";
    case "Swordflash Panther":
      return "../textures/BP08-023EN.png";
    case "Durandal the Incorruptible":
      return "../textures/BP08-024EN.png";
    case "Azord, Duke of the Mists":
      return "../textures/BP08-025EN.png";
    case "Madlance Centaur":
      return "../textures/BP08-027EN.png";
    case "Dance of Usurpation":
      return "../textures/BP08-028EN.png";
    case "Phantom Assassin":
      return "../textures/BP08-029EN.png";
    case "Zealot of Usurpation":
      return "../textures/BP08-030EN.png";
    case "Wardog":
      return "../textures/BP08-031EN.png";
    case "Mana Pistol Merc":
      return "../textures/BP08-032EN.png";
    case "Godsend Stride":
      return "../textures/BP08-034EN.png";
    case "Sweet-Tooth Medusa":
      return "../textures/BP08-035EN.png";
    case "Prophetless of Creation":
      return "../textures/BP08-U03EN.png";
    case "Unbodied Witch":
      return "../textures/BP08-038EN.png";
    case "Lovely-Heart Monika":
      return "../textures/BP08-040EN.png";
    case "Edict of Truth":
      return "../textures/BP08-041EN.png";
    case "Elusa, Magic Wunderkind":
      return "../textures/BP08-042EN.png";
    case "Morra, Monika's Familiar":
      return "../textures/BP08-044EN.png";
    case "Veridic Discovery":
      return "../textures/BP08-045EN.png";
    case "Moonshade Mage":
      return "../textures/BP08-046EN.png";
    case "Zealot of Truth":
      return "../textures/BP08-047EN.png";
    case "Rabbit Mage":
      return "../textures/BP08-048EN.png";
    case "Zealot of Destruction":
      return "../textures/BP08-050EN.png";
    case "Joy of Destruction":
      return "../textures/BP08-051EN.png";
    case "Dragon Empress Otohime":
      return "../textures/BP08-052EN.png";
    case "Azi Dahaka":
      return "../textures/BP08-053EN.png";
    case "Annerose":
      return "../textures/BP08-055EN.png";
    case "Ouroboros":
      return "../textures/BP08-057EN.png";
    case "Powerforge":
      return "../textures/BP08-058EN.png";
    case "Elios, Loyal Dragoon":
      return "../textures/BP08-059EN.png";
    case "Dragonsoul Princess":
      return "../textures/BP08-061EN.png";
    case "Vile Violet Dragon":
      return "../textures/BP08-062EN.png";
    case "Zealot of Disdain":
      return "../textures/BP08-063EN.png";
    case "Righteous Dragoon":
      return "../textures/BP08-064EN.png";
    case "Geovore":
      return "../textures/BP08-065EN.png";
    case "Draco Marionette":
      return "../textures/BP08-067EN.png";
    case "Sneer of Disdain":
      return "../textures/BP08-068EN.png";
    case "Crimson Rose Queen":
      return "../textures/BP08-069EN.png";
    case "Nepthys":
      return "../textures/BP08-U05EN.png";
    case "Tartarus, the Tormentor":
      return "../textures/BP08-072EN.png";
    case "Vuella, One-Winged Demon":
      return "../textures/BP08-074EN.png";
    case "Sonata of Silence":
      return "../textures/BP08-075EN.png";
    case "Chris Pumpkinhead":
      return "../textures/BP08-076EN.png";
    case "Salome":
      return "../textures/BP08-078EN.png";
    case "Kiss of Lust":
      return "../textures/BP08-079EN.png";
    case "Arion":
      return "../textures/BP08-080EN.png";
    case "Marian the Mummy":
      return "../textures/BP08-081EN.png";
    case "Zealot of Silence":
      return "../textures/BP08-083EN.png";
    case "Zealot of Lust":
      return "../textures/BP08-084EN.png";
    case "Manifest Malice":
      return "../textures/BP08-085EN.png";
    case "Holylord Eachtar":
      return "../textures/BP08-086EN.png";
    case "Godsworn Alexiel":
      return "../textures/BP08-087EN.png";
    case "Eidolon of Madness":
      return "../textures/BP08-089EN.png";
    case "Sekhmet":
      return "../textures/BP08-091EN.png";
    case "Vengeful Radiance":
      return "../textures/BP08-092EN.png";
    case "Collete, Holy Gunner":
      return "../textures/BP08-093EN.png";
    case "Battlefield Inquisitor":
      return "../textures/BP08-095EN.png";
    case "Manifestation of Repose":
      return "../textures/BP08-096EN.png";
    case "Malevolent Al-mi'raj":
      return "../textures/BP08-097EN.png";
    case "Zealot of Repose":
      return "../textures/BP08-098EN.png";
    case "Temple Windbear":
      return "../textures/BP08-099EN.png";
    case "Angel of the Iron Steed":
      return "../textures/BP08-101EN.png";
    case "Forgotten Sanctuary":
      return "../textures/BP08-102EN.png";

    case "Alterplane Arbiter":
      return "../textures/BP08-103EN.png";
    case "Sylvia, the Condemner":
      return "../textures/BP08-105EN.png";
    case "Sahaquiel":
      return "../textures/BP08-106EN.png";
    case "Tart Man":
      return "../textures/BP08-U07EN.png";
    case "Slash of the One":
      return "../textures/BP08-109EN.png";
    case "Reina, Evolution's Herald":
      return "../textures/BP08-110EN.png";
    case "Ephemera, Sword Angel":
      return "../textures/BP08-112EN.png";
    case "Treasure Map":
      return "../textures/BP08-113EN.png";
    case "Steelclad Minotaur":
      return "../textures/BP08-114EN.png";
    case "High Enchantress":
      return "../textures/BP08-115EN.png";
    case "Happy Pig":
      return "../textures/BP08-117EN.png";

    case "Ladica, the Stoneclaw":
      return "../textures/BP07-001EN.png";
    case "Cynthia, the Queen's Blade":
      return "../textures/BP07-003EN.png";
    case "Primal Giant":
      return "../textures/BP07-004EN.png";
    case "Setus, the Beastblade":
      return "../textures/BP07-005EN.png";
    case "Send 'Em Packing":
      return "../textures/BP07-007EN.png";
    case "Blossom Spirit":
      return "../textures/BP07-008EN.png";
    case "Avatar of Fruition":
      return "../textures/BP07-010EN.png";
    case "Divine Smithing":
      return "../textures/BP07-011EN.png";
    case "Chesire Cat":
      return "../textures/BP07-012EN.png";
    case "Ghastly Treant":
      return "../textures/BP07-013EN.png";
    case "Forest Hermit":
      return "../textures/BP07-014EN.png";
    case "Marvelously Mad Hatter":
      return "../textures/BP07-016EN.png";
    case "Fertile Aether":
      return "../textures/BP07-017EN.png";

    case "Bayleon, Sovereign Light":
      return "../textures/BP07-018EN.png";
    case "Mistolina, Forest Princess":
      return "../textures/BP07-020EN.png";
    case "Tsubaki of the Demon Blade":
      return "../textures/BP07-U02EN.png";
    case "Leod, the Crescent Blade":
      return "../textures/BP07-022EN.png";
    case "King's Might":
      return "../textures/BP07-024EN.png";
    case "Troya, Thunder of Hagelberg":
      return "../textures/BP07-025EN.png";
    case "Valse, Champion Deadeye":
      return "../textures/BP07-027EN.png";
    case "Princess's Strike":
      return "../textures/BP07-028EN.png";
    case "Swift Tigress":
      return "../textures/BP07-029EN.png";
    case "Lupine Axeman":
      return "../textures/BP07-030EN.png";
    case "Dauntless Commander":
      return "../textures/BP07-031EN.png";
    case "Tempered Aether":
      return "../textures/BP07-033EN.png";
    case "Elegance in Action":
      return "../textures/BP07-034EN.png";

    case "Tetra, Sapphire Rebel":
      return "../textures/BP07-035EN.png";
    case "Belphomet, Lord of Aiolon":
      return "../textures/BP07-037EN.png";
    case "Riley, Hydroshaman":
      return "../textures/BP07-U03EN.png";
    case "Eleanor, Cosmic Flower":
      return "../textures/BP07-039EN.png";
    case "Delta Cannon":
      return "../textures/BP07-041EN.png";
    case "Displacer Bot":
      return "../textures/BP07-042EN.png";
    case "Mechanized Lifeform":
      return "../textures/BP07-044EN.png";
    case "Splendid Conjury":
      return "../textures/BP07-045EN.png";
    case "Mechastaff Sorcerer":
      return "../textures/BP07-046EN.png";
    case "Prototype Warrior":
      return "../textures/BP07-047EN.png";
    case "Magiblade Witch":
      return "../textures/BP07-048EN.png";
    case "Presto Chango":
      return "../textures/BP07-050EN.png";
    case "Sagacious Core":
      return "../textures/BP07-051EN.png";

    case "Valdain, Cursed Shadow":
      return "../textures/BP07-052EN.png";
    case "Neptune, Tidemistress":
      return "../textures/BP07-054EN.png";
    case "Wildfire Tyrannosaur":
      return "../textures/BP07-055EN.png";
    case "Marion, Elegant Dragonewt":
      return "../textures/BP07-056EN.png";
    case "Shadow's Corrosion":
      return "../textures/BP07-058EN.png";
    case "Bubbleborne Mermaid":
      return "../textures/BP07-059EN.png";
    case "Hoarfrost Triceratops":
      return "../textures/BP07-060EN.png";
    case "Whirlwind Pteranodon":
      return "../textures/BP07-062EN.png";
    case "Dragonewt Needler":
      return "../textures/BP07-063EN.png";
    case "Lightning Velociraptor":
      return "../textures/BP07-064EN.png";
    case "Doting Dragoneer":
      return "../textures/BP07-065EN.png";
    case "Boomfish":
      return "../textures/BP07-067EN.png";
    case "Feral Aether":
      return "../textures/BP07-068EN.png";

    case "Mono, Garnet Rebel":
      return "../textures/BP07-069EN.png";
    case "Kudlak":
      return "../textures/BP07-071EN.png";
    case "Aenea, Amethyst Rebel":
      return "../textures/BP07-072EN.png";
    case "Doublame, Duke and Dame":
      return "../textures/BP07-073EN.png";
    case "Alpha Drive":
      return "../textures/BP07-075EN.png";
    case "Nicola, Forbidden Strength":
      return "../textures/BP07-076EN.png";
    case "Hellblaze Demon":
      return "../textures/BP07-078EN.png";
    case "Forbidden Art":
      return "../textures/BP07-079EN.png";
    case "Robozombie":
      return "../textures/BP07-080EN.png";
    case "Bone Drone":
      return "../textures/BP07-081EN.png";
    case "Berserk Demon":
      return "../textures/BP07-082EN.png";
    case "Ghostwriter":
      return "../textures/BP07-084EN.png";
    case "Sanguine Core":
      return "../textures/BP07-085EN.png";

    case "Limonia, Flawed Saint":
      return "../textures/BP07-086EN.png";
    case "Lapis, Glorious Seraph":
      return "../textures/BP07-U06EN.png";
    case "Father Refinement":
      return "../textures/BP07-089EN.png";
    case "Marione, Light of Balance":
      return "../textures/BP07-090EN.png";
    case "Augmentation Bestowal":
      return "../textures/BP07-092EN.png";
    case "Bunny-Eared Administrator":
      return "../textures/BP07-093EN.png";
    case "Robofalcon":
      return "../textures/BP07-094EN.png";
    case "Marcotte, Heretical Sister":
      return "../textures/BP07-096EN.png";
    case "Ironknuckle Nun":
      return "../textures/BP07-097EN.png";
    case "Dark Bishop":
      return "../textures/BP07-098EN.png";
    case "Meowskers, Ruff-Tuff Major":
      return "../textures/BP07-100EN.png";
    case "Saintly Core":
      return "../textures/BP07-101EN.png";
    case "Meowskers Ambush!":
      return "../textures/BP07-102EN.png";

    case "Technolord":
      return "../textures/BP07-U07EN.png";
    case "Technolord (b)":
      return "../textures/BP07-103EN.png";
    case "Viridia Magna":
      return "../textures/BP07-104EN.png";
    case "Mechawing Angel":
      return "../textures/BP07-106EN.png";
    case "Desert Pathfinder":
      return "../textures/BP07-107EN.png";
    case "Maisha, Hero of Purgation":
      return "../textures/BP07-108EN.png";
    case "Robogoblin":
      return "../textures/BP07-110EN.png";
    case "Colorful Cook":
      return "../textures/BP07-112EN.png";
    case "Purgation's Blade":
      return "../textures/BP07-113EN.png";
    case "Aldis, Trendsetting Seraph":
      return "../textures/BP07-114EN.png";
    case "Mechagun Wielder":
      return "../textures/BP07-116EN.png";
    case "Extreme Carrot":
      return "../textures/BP07-117EN.png";

    case "Uzuki Shimamura [P.C.S.]":
      return "../textures/CSD02a-001EN.png";
    case "Kyoko Igarashi [P.C.S.]":
      return "../textures/CSD02a-002EN.png";
    case "Miho Kohinata [P.C.S.]":
      return "../textures/CSD02a-003EN.png";
    case "Chika Yokoyama":
      return "../textures/CSD02a-004EN.png";
    case "Momoka Sakurai":
      return "../textures/CSD02a-005EN.png";
    case "Akiha Ikebukuro":
      return "../textures/CSD02a-007EN.png";
    case "Nene Kurihara":
      return "../textures/CSD02a-009EN.png";
    case "Karin Domyoji":
      return "../textures/CSD02a-011EN.png";

    case "Rin Shibuya [Triad Primus]":
      return "../textures/CSD02b-001EN.png";
    case "Nao Kamiya [Over the Rainbow]":
      return "../textures/CSD02b-002EN.png";
    case "Karen Hojo [Song for Life]":
      return "../textures/CSD02b-003EN.png";
    case "Yasuha Okazaki":
      return "../textures/CSD02b-004EN.png";
    case "Yukimi Sajo":
      return "../textures/CSD02b-005EN.png";
    case "Kako Takafuji":
      return "../textures/CSD02b-007EN.png";
    case "Chizuru Matsuo":
      return "../textures/CSD02b-009EN.png";
    case "Seira Mizuki":
      return "../textures/CSD02b-010EN.png";

    case "Mio Honda [Positive Passion]":
      return "../textures/CSD02c-001EN.png";
    case "Aiko Takamori [Handmade Hapiness]":
      return "../textures/CSD02c-002EN.png";
    case "Akane Hino [Positive Passion]":
      return "../textures/CSD02c-003EN.png";
    case "Kaoru Ryuzaki":
      return "../textures/CSD02c-004EN.png";
    case "Suzuho Ueda":
      return "../textures/CSD02c-005EN.png";
    case "Miria Akagi":
      return "../textures/CSD02c-007EN.png";
    case "Miu Yaguchi":
      return "../textures/CSD02c-009EN.png";
    case "Kumiko Matsuyama":
      return "../textures/CSD02c-010EN.png";

    case "Aiko Takamori":
      return "../textures/CP02-001EN.png";
    case "Miku Maekawa":
      return "../textures/CP02-U01aEN.png";
    case "Yuzu Kitami":
      return "../textures/CP02-004EN.png";
    case "Anastasia":
      return "../textures/CP02-U02EN.png";
    case "Brand New Beat":
      return "../textures/CP02-007EN.png";
    case "Shinobu Kudo":
      return "../textures/CP02-008EN.png";
    case "Yumi Aiba":
      return "../textures/CP02-009EN.png";
    case "Goddess by the Sunlit Sea":
      return "../textures/CP02-011EN.png";
    case "Honoka Ayase":
      return "../textures/CP02-012EN.png";
    case "Azuki Momoi":
      return "../textures/CP02-013EN.png";
    case "Kana Imai":
      return "../textures/CP02-014EN.png";
    case "Otaha Umeki":
      return "../textures/CP02-016EN.png";
    case "A Single Vessel":
      return "../textures/CP02-017EN.png";

    case "Kyoko Igarashi":
      return "../textures/CP02-018EN.png";
    case "Nagi Hisakawa":
      return "../textures/CP02-020EN.png";
    case "Rin Shibuya":
      return "../textures/CP02-021EN.png";
    case "Mio Honda":
      return "../textures/CP02-022EN.png";
    case "Uzuki Shimamura":
      return "../textures/CP02-023EN.png";
    case "Anzu Futaba":
      return "../textures/CP02-025EN.png";
    case "Karen Hojo":
      return "../textures/CP02-026EN.png";
    case "Sparkling Days":
      return "../textures/CP02-028EN.png";
    case "Nao Kamiya":
      return "../textures/CP02-029EN.png";
    case "Mayu Sakuma":
      return "../textures/CP02-030EN.png";
    case "Kirari Moroboshi":
      return "../textures/CP02-031EN.png";
    case "Miho Kohinata":
      return "../textures/CP02-032EN.png";
    case "Angelic Maid":
      return "../textures/CP02-034EN.png";

    case "Mizuki Kawashima":
      return "../textures/CP02-035EN.png";
    case "Shiki Ichinose":
      return "../textures/CP02-036EN.png";
    case "Syuko Shiomi":
      return "../textures/CP02-U06bEN.png";
    case "Kanade Hayami":
      return "../textures/CP02-039EN.png";
    case "Center Street":
      return "../textures/CP02-041EN.png";
    case "Hina Araki":
      return "../textures/CP02-042EN.png";
    case "Frederica Miyamoto":
      return "../textures/CP02-044EN.png";
    case "Precocious Little Devil":
      return "../textures/CP02-045EN.png";
    case "Sarina Matsumoto":
      return "../textures/CP02-046EN.png";
    case "Rika Jougasaki":
      return "../textures/CP02-047EN.png";
    case "Sae Kobayakawa":
      return "../textures/CP02-049EN.png";
    case "Tomoe Murakami":
      return "../textures/CP02-050EN.png";
    case "Full Bloom Panorama":
      return "../textures/CP02-051EN.png";

    case "Akari Tsujino":
      return "../textures/CP02-052EN.png";
    case "Yui Ohtsuki":
      return "../textures/CP02-053EN.png";
    case "Fumika Sagisawa":
      return "../textures/CP02-U08aEN.png";
    case "Akira Sunazuka":
      return "../textures/CP02-056EN.png";
    case "Tsukasa Kiryu":
      return "../textures/CP02-057EN.png";
    case "Arisu Tachibana":
      return "../textures/CP02-059EN.png";
    case "Yuka Nakano":
      return "../textures/CP02-060EN.png";
    case "Unbound Emotion":
      return "../textures/CP02-062EN.png";
    case "Tokiko Zaizen":
      return "../textures/CP02-063EN.png";
    case "Noriko Shiina":
      return "../textures/CP02-064EN.png";
    case "Yukari Mizumoto":
      return "../textures/CP02-065EN.png";
    case "Noa Takamine":
      return "../textures/CP02-066EN.png";
    case "Mode Estivale":
      return "../textures/CP02-068EN.png";

    case "Ranko Kanzaki":
      return "../textures/CP02-069EN.png";
    case "Sachiko Koshimizu":
      return "../textures/CP02-U10EN.png";
    case "Takumi Mukai":
      return "../textures/CP02-072EN.png";
    case "Chitose Kurosaki":
      return "../textures/CP02-074EN.png";
    case "Whispers of a Dream":
      return "../textures/CP02-075EN.png";
    case "Chiyo Shirayuki":
      return "../textures/CP02-076EN.png";
    case "Aki Yamato":
      return "../textures/CP02-077EN.png";
    case "My Life, My Sounds":
      return "../textures/CP02-079EN.png";
    case "Ryo Matsunaga":
      return "../textures/CP02-080EN.png";
    case "Mirei Hayasaka":
      return "../textures/CP02-081EN.png";
    case "Rina Fujimoto":
      return "../textures/CP02-082EN.png";
    case "Syoko Hoshi":
      return "../textures/CP02-083EN.png";
    case "Last Daylight":
      return "../textures/CP02-085EN.png";

    case "Kaede Takagaki":
      return "../textures/CP02-U11EN.png";
    case "Shin Sato":
      return "../textures/CP02-087EN.png";
    case "Nana Abe":
      return "../textures/CP02-089EN.png";
    case "Akane Hino":
      return "../textures/CP02-091EN.png";
    case "Classroom Lily":
      return "../textures/CP02-092EN.png";
    case "Risa Matoba":
      return "../textures/CP02-093EN.png";
    case "Haru Yuuki":
      return "../textures/CP02-094EN.png";
    case "Psychic Maiden":
      return "../textures/CP02-096EN.png";
    case "Natalia":
      return "../textures/CP02-097EN.png";
    case "Shizuku Oikawa":
      return "../textures/CP02-098EN.png";
    case "Layla":
      return "../textures/CP02-099EN.png";
    case "Sanae Katagiri":
      return "../textures/CP02-101EN.png";
    case "Winter Night Prayer":
      return "../textures/CP02-102EN.png";

    case "New Generations":
      return "../textures/CP02-U13bEN.png";
    case "New Wave":
      return "../textures/CP02-104EN.png";
    case "Master Trainer":
      return "../textures/CP02-105EN.png";
    case "Expert Trainer":
      return "../textures/CP02-106EN.png";
    case "Trainer":
      return "../textures/CP02-107EN.png";
    case "Rookie Trainer":
      return "../textures/CP02-108EN.png";

    case "Lymaga, Forest Champion":
      return "../textures/BP06-001EN.png";
    case "Amataz, Fairy Blader":
      return "../textures/BP06-003EN.png";
    case "Greenbrier Elf":
      return "../textures/BP06-004EN.png";
    case "Wildwood Matriarch":
      return "../textures/BP06-005EN.png";
    case "Fairy Dragon":
      return "../textures/BP06-007EN.png";
    case "Woodland Cleaver":
      return "../textures/BP06-008EN.png";
    case "Assault Jaguar":
      return "../textures/BP06-010EN.png";
    case "Spiritshine":
      return "../textures/BP06-011EN.png";
    case "Greenwood Guardian":
      return "../textures/BP06-012EN.png";
    case "Crossbow Sniper":
      return "../textures/BP06-013EN.png";
    case "Mallet Monkey":
      return "../textures/BP06-014EN.png";
    case "Elven Sentry":
      return "../textures/BP06-016EN.png";
    case "Synchronized Slash":
      return "../textures/BP06-017EN.png";
    case "Kagemitsu, Matchless Blade":
      return "../textures/BP06-018EN.png";
    case "Ralmia, Sonic Racer":
      return "../textures/BP06-019EN.png";
    case "Steadfast Samurai":
      return "../textures/BP06-021EN.png";
    case "Hero of Antiquity":
      return "../textures/BP06-022EN.png";
    case "Courtly Dance":
      return "../textures/BP06-024EN.png";
    case "Quickdraw Maven":
      return "../textures/BP06-025EN.png";
    case "Twinsword Master":
      return "../textures/BP06-027EN.png";
    case "Grand Acquisition":
      return "../textures/BP06-028EN.png";
    case "Samurai Outlaw":
      return "../textures/BP06-029EN.png";
    case "Adept Thief":
      return "../textures/BP06-031EN.png";
    case "Petalwink Paladin":
      return "../textures/BP06-032EN.png";
    case "Levin Scholar":
      return "../textures/BP06-033EN.png";
    case "Breakneck Draw":
      return "../textures/BP06-034EN.png";
    case "Kuon, Founder of Onmyodo":
      return "../textures/BP06-U03EN.png";
    case "Mysteria, Magic Founder":
      return "../textures/BP06-036EN.png";
    case "Curse Crafter":
      return "../textures/BP06-038EN.png";
    case "Hulking Giant":
      return "../textures/BP06-040EN.png";
    case "Shikigami Summons":
      return "../textures/BP06-041EN.png";
    case "Demoncaller":
      return "../textures/BP06-042EN.png";
    case "Traditional Sorcerer":
      return "../textures/BP06-044EN.png";
    case "Crimson Meteor Storm":
      return "../textures/BP06-045EN.png";
    case "Talisman Disciple":
      return "../textures/BP06-046EN.png";
    case "Charming Gentlemouse":
      return "../textures/BP06-047EN.png";
    case "Passionate Potioneer":
      return "../textures/BP06-049EN.png";
    case "Golem's Rampage":
      return "../textures/BP06-050EN.png";
    case "Mirror of Truth":
      return "../textures/BP06-051EN.png";
    case "Garyu, Surpreme Dragonkin":
      return "../textures/BP06-052EN.png";
    case "Filene, Whitefrost Dragonewt":
      return "../textures/BP06-U04EN.png";
    case "Phoenix Empress":
      return "../textures/BP06-055EN.png";
    case "Wyrm God of the Skies":
      return "../textures/BP06-056EN.png";
    case "Whitefrost Whisper":
      return "../textures/BP06-058EN.png";
    case "Ice Dancing Dragonewt":
      return "../textures/BP06-059EN.png";
    case "Jadelong Tactician":
      return "../textures/BP06-061EN.png";
    case "Aquascale Stalwart":
      return "../textures/BP06-062EN.png";
    case "Swordwhip Dragoon":
      return "../textures/BP06-063EN.png";
    case "Dragonblader":
      return "../textures/BP06-064EN.png";
    case "Trident Merman":
      return "../textures/BP06-066EN.png";
    case "Dragon Chef":
      return "../textures/BP06-067EN.png";
    case "Flamewinged Might":
      return "../textures/BP06-068EN.png";
    case "Ginsetsu, Great Fox":
      return "../textures/BP06-069EN.png";
    case "Aragavy the Berserker":
      return "../textures/BP06-071EN.png";
    case "Shuten-Doji":
      return "../textures/BP06-072EN.png";
    case "Bear Pelt Warrior":
      return "../textures/BP06-074EN.png";
    case "Yuzuki, Righteous Demon":
      return "../textures/BP06-075EN.png";
    case "Kasha":
      return "../textures/BP06-076EN.png";
    case "Cougar Pelt Warrior":
      return "../textures/BP06-077EN.png";
    case "Unleash the Nightmare":
      return "../textures/BP06-079EN.png";
    case "Zashiki-Warashi":
      return "../textures/BP06-080EN.png";
    case "Antelope Pelt Warrior":
      return "../textures/BP06-082EN.png";
    case "Rookie Succubus":
      return "../textures/BP06-083EN.png";
    case "Demonic Procession":
      return "../textures/BP06-084EN.png";
    case "Berserker's Pelt":
      return "../textures/BP06-085EN.png";
    case "Wilbert, Grand Knight":
      return "../textures/BP06-086EN.png";
    case "Karula, Arts Master":
      return "../textures/BP06-087EN.png";
    case "Saintly Leader":
      return "../textures/BP06-089EN.png";
    case "Phantom Blade Wielder":
      return "../textures/BP06-090EN.png";
    case "Manifest Devotion":
      return "../textures/BP06-092EN.png";
    case "Holy Lancer":
      return "../textures/BP06-093EN.png";
    case "Boost Kicker":
      return "../textures/BP06-095EN.png";
    case "Feather Sanctuary":
      return "../textures/BP06-096EN.png";
    case "Winged Staff Priestess":
      return "../textures/BP06-097EN.png";
    case "Gravity Grappler":
      return "../textures/BP06-098EN.png";
    case "Barrage Brawler":
      return "../textures/BP06-099EN.png";
    case "Holy Counterattack":
      return "../textures/BP06-101EN.png";
    case "Focus":
      return "../textures/BP06-102EN.png";
    case "Mammoth God's Colosseum":
      return "../textures/BP06-103EN.png";
    case "Badb Catha":
      return "../textures/BP06-104EN.png";
    case "Mithra, Daybreak Diety":
      return "../textures/BP06-106EN.png";
    case "Fall from Grace":
      return "../textures/BP06-108EN.png";
    case "Colosseum on High":
      return "../textures/BP06-109EN.png";
    case "Chaht, Ringside Announcer":
      return "../textures/BP06-110EN.png";
    case "Clash of Heroes":
      return "../textures/BP06-112EN.png";
    case "Biofrabrication":
      return "../textures/BP06-113EN.png";
    case "Sweet-Tooth Sleuth":
      return "../textures/BP06-114EN.png";
    case "Bazooka Goblins":
      return "../textures/BP06-115EN.png";
    case "Sentry Gate":
      return "../textures/BP06-117EN.png";

    case "Izudia, Omen of Unkilling":
      return "../textures/BP05-001EN.png";
    case "Spinaria, Keeper of Enigmas":
      return "../textures/BP05-003EN.png";
    case "Apostle of Unkilling":
      return "../textures/BP05-004EN.png";
    case "Morton the Manipulator":
      return "../textures/BP05-006EN.png";
    case "Fairy Torrent":
      return "../textures/BP05-007EN.png";
    case "Disciple of Unkilling":
      return "../textures/BP05-008EN.png";
    case "Noah, Vengeful Puppeteer":
      return "../textures/BP05-009EN.png";
    case "Mark of the Unkilling":
      return "../textures/BP05-011EN.png";
    case "Servant of Unkilling":
      return "../textures/BP05-012EN.png";
    case "Mechanical Bowman":
      return "../textures/BP05-013EN.png";
    case "Flower Doll":
      return "../textures/BP05-014EN.png";
    case "Automaton Soldier":
      return "../textures/BP05-016EN.png";
    case "Mark of the Six":
      return "../textures/BP05-017EN.png";

    case "Octrice, Omen of Usurpation":
      return "../textures/BP05-018EN.png";
    case "Magna Legacy":
      return "../textures/BP05-020EN.png";
    case "Apostle of Usurpation":
      return "../textures/BP05-021EN.png";
    case "Empyreal Swordsman":
      return "../textures/BP05-023EN.png";
    case "Confront Adversity":
      return "../textures/BP05-024EN.png";
    case "Disciple of Usurpation":
      return "../textures/BP05-025EN.png";
    case "Fervent Maachine Soldier":
      return "../textures/BP05-026EN.png";
    case "Geno, Machine Artisan":
      return "../textures/BP05-027EN.png";
    case "Servant of Usurpation":
      return "../textures/BP05-029EN.png";
    case "Captain Meteo":
      return "../textures/BP05-030EN.png";
    case "Gravikinetic Warrior":
      return "../textures/BP05-032EN.png";
    case "Usurping Spineblade":
      return "../textures/BP05-033EN.png";
    case "Avaritia":
      return "../textures/BP05-034EN.png";

    case "Raio, Omen of Truth":
      return "../textures/BP05-035EN.png";
    case "Lishenna, Omen of Destruction":
      return "../textures/BP05-U03EN.png";
    case "Apostle of Truth":
      return "../textures/BP05-038EN.png";
    case "Safira, Synthetic Beast":
      return "../textures/BP05-040EN.png";
    case "Destructive Refrain":
      return "../textures/BP05-041EN.png";
    case "Iron Staff Mechanic":
      return "../textures/BP05-042EN.png";
    case "Truth's Adjudication":
      return "../textures/BP05-044EN.png";
    case "Monochromatic Destruction":
      return "../textures/BP05-045EN.png";
    case "Disciple of Truth":
      return "../textures/BP05-046EN.png";
    case "Disciple of Destruction":
      return "../textures/BP05-047EN.png";
    case "Servant of Destruction":
      return "../textures/BP05-048EN.png";
    case "Honest Cohort":
      return "../textures/BP05-050EN.png";
    case "Metaproduction":
      return "../textures/BP05-051EN.png";

    case "Galmieux, Omen of Disdain":
      return "../textures/BP05-052EN.png";
    case "Electromagical Rhino":
      return "../textures/BP05-054EN.png";
    case "Apostle of Disdain":
      return "../textures/BP05-055EN.png";
    case "God Bullet Golem":
      return "../textures/BP05-057EN.png";
    case "Disdainful Rending":
      return "../textures/BP05-058EN.png";
    case "Disciple of Disdain":
      return "../textures/BP05-059EN.png";
    case "Cursed Stone":
      return "../textures/BP05-060EN.png";
    case "Amethyst Giant":
      return "../textures/BP05-062EN.png";
    case "Servant of Disdain":
      return "../textures/BP05-063EN.png";
    case "Silver Automaton":
      return "../textures/BP05-064EN.png";
    case "Airship Whale":
      return "../textures/BP05-065EN.png";
    case "Colossal Construct":
      return "../textures/BP05-067EN.png";
    case "Total Domination":
      return "../textures/BP05-068EN.png";

    case "Valnareik, Omen of Lust":
      return "../textures/BP05-U05EN.png";
    case "Rulenye, Omen of Silence":
      return "../textures/BP05-070EN.png";
    case "Apostle of Lust":
      return "../textures/BP05-072EN.png";
    case "Apostle of Silence":
      return "../textures/BP05-073EN.png";
    case "Disciple of Lust":
      return "../textures/BP05-075EN.png";
    case "Masked Puppet":
      return "../textures/BP05-076EN.png";
    case "Wings of Lust":
      return "../textures/BP05-078EN.png";
    case "Silent Purge":
      return "../textures/BP05-079EN.png";
    case "Servant of Lust":
      return "../textures/BP05-080EN.png";
    case "Servant of Silence":
      return "../textures/BP05-082EN.png";
    case "Hamelin":
      return "../textures/BP05-083EN.png";
    case "Embracing Wings":
      return "../textures/BP05-084EN.png";
    case "Thundering Roar":
      return "../textures/BP05-085EN.png";

    case "Marwynn, Omen of Repose":
      return "../textures/BP05-086EN.png";
    case "Deus Ex Machina":
      return "../textures/BP05-088EN.png";
    case "Apostle of Repose":
      return "../textures/BP05-089EN.png";
    case "Hakrabi":
      return "../textures/BP05-091EN.png";
    case "Ancient Protector":
      return "../textures/BP05-092EN.png";
    case "Disciple of Repose":
      return "../textures/BP05-093EN.png";
    case "Unidentified Subject":
      return "../textures/BP05-094EN.png";
    case "Silver Cog Spinner":
      return "../textures/BP05-096EN.png";
    case "Servant of Repose":
      return "../textures/BP05-097EN.png";
    case "Demon's Epitaph":
      return "../textures/BP05-098EN.png";
    case "The Saviors":
      return "../textures/BP05-100EN.png";
    case "Realm of Repose":
      return "../textures/BP05-101EN.png";
    case "Ancient Amplifier":
      return "../textures/BP05-102EN.png";

    case "Mjerrabaine, Omen of One":
      return "../textures/BP05-103EN.png";
    case "Gilnelise, Omen of Craving":
      return "../textures/BP05-U07EN.png";
    case "Apostle of Craving":
      return "../textures/BP05-106EN.png";
    case "Lyrial, Archer Throne":
      return "../textures/BP05-108EN.png";
    case "Feena, Dynamite Daredevil":
      return "../textures/BP05-109EN.png";
    case "Rosa, Mech Wing Maiden":
      return "../textures/BP05-110EN.png";
    case "Enlightenment":
      return "../textures/BP05-112EN.png";
    case "Craving's Splendor":
      return "../textures/BP05-113EN.png";
    case "Cat Cannoneer":
      return "../textures/BP05-114EN.png";
    case "Steel Demolitionist":
      return "../textures/BP05-116EN.png";
    case "Gliesaray":
      return "../textures/BP05-117EN.png";

    case "Cassiopeia":
      return "../textures/BP04-U01EN.png";
    case "C.C. Woodland Witch":
      return "../textures/BP04-002EN.png";
    case "Deepwood Anomaly":
      return "../textures/BP04-003EN.png";
    case "King Elephant":
      return "../textures/BP04-005EN.png";
    case "Fashionista Nelcha":
      return "../textures/BP04-007EN.png";
    case "Spring-Green Protection":
      return "../textures/BP04-008EN.png";
    case "Inviolable Verdancy":
      return "../textures/BP04-009EN.png";
    case "Sukuna, Brave and Small":
      return "../textures/BP04-010EN.png";
    case "Dolorblade Demon":
      return "../textures/BP04-012EN.png";
    case "Elf Song":
      return "../textures/BP04-013EN.png";
    case "Starry Elf":
      return "../textures/BP04-014EN.png";
    case "Fita the Gentle Elf":
      return "../textures/BP04-015EN.png";
    case "Dryad":
      return "../textures/BP04-017EN.png";
    case "Beetle Warrior":
      return "../textures/BP04-018EN.png";
    case "Ivy Spellbomb":
      return "../textures/BP04-019EN.png";
    case "Mars, Silent Flame General":
      return "../textures/BP04-020EN.png";
    case "Gawain of the Round Table":
      return "../textures/BP04-U02EN.png";
    case "Barbarossa":
      return "../textures/BP04-023EN.png";
    case "Perseus":
      return "../textures/BP04-025EN.png";
    case "Cyclone Blade":
      return "../textures/BP04-026EN.png";
    case "Chivalrous Charge":
      return "../textures/BP04-027EN.png";
    case "Shrouded Assassin":
      return "../textures/BP04-028EN.png";
    case "Lord General Romeo":
      return "../textures/BP04-030EN.png";
    case "Round Table Assembly":
      return "../textures/BP04-031EN.png";
    case "Princess Juliet":
      return "../textures/BP04-032EN.png";
    case "Flail Knight":
      return "../textures/BP04-033EN.png";
    case "Pollux":
      return "../textures/BP04-034EN.png";
    case "Tristan of the Round Table":
      return "../textures/BP04-035EN.png";
    case "Armor of the Stars":
      return "../textures/BP04-037EN.png";
    case "Wordwielder Ginger":
      return "../textures/BP04-038EN.png";
    case "Giant Chimera":
      return "../textures/BP04-040EN.png";
    case "Star Reader Stella":
      return "../textures/BP04-041EN.png";
    case "Europa":
      return "../textures/BP04-042EN.png";
    case "Chain of Calling":
      return "../textures/BP04-044EN.png";
    case "Noble Instruction":
      return "../textures/BP04-045EN.png";
    case "Freshman Lou":
      return "../textures/BP04-046EN.png";
    case "Magic Illusionist":
      return "../textures/BP04-047EN.png";
    case "Concentration":
      return "../textures/BP04-049EN.png";
    case "Show of Loyalty":
      return "../textures/BP04-050EN.png";
    case "Dazzling Healer":
      return "../textures/BP04-051EN.png";
    case "Mage of Nightfall":
      return "../textures/BP04-053EN.png";
    case "Astrologist of the Mist":
      return "../textures/BP04-054EN.png";
    case "Magic Owl":
      return "../textures/BP04-055EN.png";
    case "Starseer's Telescope":
      return "../textures/BP04-056EN.png";
    case "Sibyl of the Waterwyrm":
      return "../textures/BP04-057EN.png";
    case "Kallen, Crimson Yaksha":
      return "../textures/BP04-058EN.png";
    case "Python":
      return "../textures/BP04-059EN.png";
    case "Prime Dragon Keeper":
      return "../textures/BP04-U04EN.png";
    case "Star Phoenix":
      return "../textures/BP04-064EN.png";
    case "Lightning Blast":
      return "../textures/BP04-066EN.png";
    case "Guren Revolt":
      return "../textures/BP04-067EN.png";
    case "Venomous Pucewyrm":
      return "../textures/BP04-068EN.png";
    case "Cetus":
      return "../textures/BP04-069EN.png";
    case "Dragonewt Fist":
      return "../textures/BP04-071EN.png";
    case "Divine Tiger":
      return "../textures/BP04-072EN.png";
    case "Dragonrearer Matilda":
      return "../textures/BP04-073EN.png";
    case "Aqua Nerid":
      return "../textures/BP04-074EN.png";
    case "Hippocampus":
      return "../textures/BP04-075EN.png";
    case "Scaled Berserker":
      return "../textures/BP04-077EN.png";
    case "Dragon's Nest":
      return "../textures/BP04-078EN.png";
    case "Venomfang Medusa":
      return "../textures/BP04-U05EN.png";
    case "Howling Demon":
      return "../textures/BP04-080EN.png";
    case "Demonlord Eachtar":
      return "../textures/BP04-082EN.png";
    case "Lelouch, Leader of the Black Knights":
      return "../textures/BP04-SP01EN.png";
    case "Stheno":
      return "../textures/BP04-084EN.png";
    case "Trial of the Gorgons":
      return "../textures/BP04-086EN.png";
    case "Fenrir":
      return "../textures/BP04-087EN.png";
    case "Euryale":
      return "../textures/BP04-089EN.png";
    case "Grave Desecration":
      return "../textures/BP04-090EN.png";
    case "Emperor's Command":
      return "../textures/BP04-091EN.png";
    case "Demonic Drummer":
      return "../textures/BP04-092EN.png";
    case "Castor":
      return "../textures/BP04-093EN.png";
    case "Frogbat":
      return "../textures/BP04-094EN.png";
    case "Scorpius":
      return "../textures/BP04-096EN.png";
    case "Venomous Bite":
      return "../textures/BP04-097EN.png";
    case "Aether of the White Wing":
      return "../textures/BP04-U06EN.png";
    case "Dark Jeanne":
      return "../textures/BP04-099EN.png";
    case "Zoe, Princess of Goldenia":
      return "../textures/BP04-101EN.png";
    case "Andromeda":
      return "../textures/BP04-103EN.png";
    case "Globe of the Starways":
      return "../textures/BP04-104EN.png";
    case "Dark Charisma":
      return "../textures/BP04-105EN.png";
    case "Star Priestess":
      return "../textures/BP04-106EN.png";
    case "Calydonian Boar":
      return "../textures/BP04-108EN.png";
    case "Star Torrent":
      return "../textures/BP04-109EN.png";
    case "Starchaser Sprite":
      return "../textures/BP04-110EN.png";
    case "Sister of Punishment":
      return "../textures/BP04-111EN.png";
    case "Mist Shaman":
      return "../textures/BP04-112EN.png";
    case "Octobishop":
      return "../textures/BP04-114EN.png";
    case "Candelabra of Prayers":
      return "../textures/BP04-115EN.png";
    case "Zodiac Demon":
      return "../textures/BP04-116EN.png";
    case "Israfil":
      return "../textures/BP04-117EN.png";
    case "Grimnir, War Cyclone":
      return "../textures/BP04-119EN.png";
    case "Arriet, Soothing Harpist":
      return "../textures/BP04-121EN.png";
    case "Staircase to Paradise":
      return "../textures/BP04-122EN.png";
    case "Purehearted Singer":
      return "../textures/BP04-123EN.png";
    case "Goblin Princess":
      return "../textures/BP04-124EN.png";
    case "Mystic Ring":
      return "../textures/BP04-126EN.png";
    case "Owlcat":
      return "../textures/BP04-127EN.png";
    case "Mr. Full Moon":
      return "../textures/BP04-129EN.png";
    case "Night's Way":
      return "../textures/BP04-130EN.png";

    case "Beauty and the Beast":
      return "../textures/BP03-001EN.png";
    case "Cosmos Fang":
      return "../textures/BP03-002EN.png";
    case "Magical Fairy, Lilac":
      return "../textures/BP03-U01EN.png";
    case "Slade Blossoming Wolf":
      return "../textures/BP03-005EN.png";
    case "Elf Twins' Assault":
      return "../textures/BP03-007EN.png";
    case "Abby the Axe Girl":
      return "../textures/BP03-008EN.png";
    case "Gerbera Bear":
      return "../textures/BP03-009EN.png";
    case "Flower Princess":
      return "../textures/BP03-012EN.png";
    case "Wood of Brambles":
      return "../textures/BP03-011EN.png";
    case "Fen Sprite":
      return "../textures/BP03-013EN.png";
    case "Tweedle Dum, Tweedle Dee":
      return "../textures/BP03-014EN.png";
    case "Floral Breeze":
      return "../textures/BP03-016EN.png";
    case "Woodland Band":
      return "../textures/BP03-017EN.png";
    case "Cinderella":
      return "../textures/BP03-U02EN.png";
    case "Valiant Fencer":
      return "../textures/BP03-019EN.png";
    case "Maisy, Red Riding Hood":
      return "../textures/BP03-021EN.png";
    case "Amerro, Spear Knight":
      return "../textures/BP03-022EN.png";
    case "Castle in the Sky":
      return "../textures/BP03-024EN.png";
    case "Young Ogrehunter Momo":
      return "../textures/BP03-025EN.png";
    case "Mach Knight":
      return "../textures/BP03-026EN.png";
    case "Kiss of the Princess":
      return "../textures/BP03-028EN.png";
    case "Rabbit Ear Attendant":
      return "../textures/BP03-029EN.png";
    case "Old Man and Old Woman":
      return "../textures/BP03-030EN.png";
    case "Bladed Hedgehog":
      return "../textures/BP03-031EN.png";
    case "Ironwrought Defender":
      return "../textures/BP03-033EN.png";
    case "Heroic Entry":
      return "../textures/BP03-034EN.png";
    case "Wizardess of Oz":
      return "../textures/BP03-U03EN.png";
    case "Witch of Calamity, Millie Parfait":
      return "../textures/BP03-SP01EN.png";
    case "Mystic King":
      return "../textures/BP03-037EN.png";
    case "Falise, Leonardian Mage":
      return "../textures/BP03-039EN.png";
    case "Milady, Mystic Queen":
      return "../textures/BP03-040EN.png";
    case "Check":
      return "../textures/BP03-042EN.png";
    case "Mr. Heinlein, Shadow Mage":
      return "../textures/BP03-043EN.png";
    case "Magical Knight":
      return "../textures/BP03-044EN.png";
    case "Gingerbread House":
      return "../textures/BP03-046EN.png";
    case "It's a Sweets Buffet!":
      return "../textures/BP03-047EN.png";
    case "Witch of Sweets":
      return "../textures/BP03-048EN.png";
    case "Magical Rook":
      return "../textures/BP03-050EN.png";
    case "Magical Bishop":
      return "../textures/BP03-051EN.png";
    case "Blitz":
      return "../textures/BP03-052EN.png";
    case "Witch's Cauldron":
      return "../textures/BP03-053EN.png";
    case "The Cauldron of Calamity":
      return "../textures/BP03-054EN.png";
    case "Jabberwock U":
      return "../textures/BP03-U04EN.png";
    case "Lævateinn Dragon":
      return "../textures/BP03-056EN.png";
    case "Red Ragewyrm":
      return "../textures/BP03-059EN.png";
    case "Draconir, Knuckle Dragon":
      return "../textures/BP03-060EN.png";
    case "Tilting at Windmills":
      return "../textures/BP03-062EN.png";
    case "Master of Draconic Arts":
      return "../textures/BP03-063EN.png";
    case "Hammer Dragonewt":
      return "../textures/BP03-064EN.png";
    case "Draconic Smash":
      return "../textures/BP03-066EN.png";
    case "Elder Tortoise":
      return "../textures/BP03-067EN.png";
    case "Trinity Dragon":
      return "../textures/BP03-068EN.png";
    case "Dragon Summoner":
      return "../textures/BP03-069EN.png";
    case "Lance Lizard":
      return "../textures/BP03-071EN.png";
    case "Armor Burst":
      return "../textures/BP03-072EN.png";
    case "Dark Alice":
      return "../textures/BP03-U05EN.png";
    case "Masquerade Ghost":
      return "../textures/BP03-074EN.png";
    case "Odile, Black Swan":
      return "../textures/BP03-076EN.png";
    case "Demonium, Punk Devil":
      return "../textures/BP03-077EN.png";
    case "Baccherus, Peppy Ghostie":
      return "../textures/BP03-078EN.png";
    case "Demon Maestro":
      return "../textures/BP03-080EN.png";
    case "Trombone Devil":
      return "../textures/BP03-081EN.png";
    case "Furtive Fangs":
      return "../textures/BP03-083EN.png";
    case "Pumpkin Necromancer":
      return "../textures/BP03-084EN.png";
    case "Parade Raven":
      return "../textures/BP03-085EN.png";
    case "Mischievous Zombie":
      return "../textures/BP03-086EN.png";
    case "Devilish Flautist":
      return "../textures/BP03-088EN.png";
    case "Infernal Orchestration":
      return "../textures/BP03-089EN.png";
    case "Princess Snow White":
      return "../textures/BP03-090EN.png";
    case "Diamond Master":
      return "../textures/BP03-091EN.png";
    case "Odette, White Swan":
      return "../textures/BP03-U06EN.png";
    case "Wingy Chirpy Gemstone":
      return "../textures/BP03-094EN.png";
    case "Alice's Adventure":
      return "../textures/BP03-096EN.png";
    case "White Knight":
      return "../textures/BP03-097EN.png";
    case "Ruby Falcon":
      return "../textures/BP03-098EN.png";
    case "March Hare's Teatime":
      return "../textures/BP03-100EN.png";
    case "Tin Soldier":
      return "../textures/BP03-101EN.png";
    case "Pinion Prince":
      return "../textures/BP03-102EN.png";
    case "Birdkeeping Disciple":
      return "../textures/BP03-104EN.png";
    case "Amethyst Lion":
      return "../textures/BP03-105EN.png";
    case "Bejeweled Shrine":
      return "../textures/BP03-106EN.png";
    case "Alice, Wonderland Explorer":
      return "../textures/BP03-107EN.png";
    case "Angel of Chaos":
      return "../textures/BP03-109EN.png";
    case "Rapunzel":
      return "../textures/BP03-110EN.png";
    case "Seraph of Sin":
      return "../textures/BP03-111EN.png";
    case "Garuel, Seraphic Leo":
      return "../textures/BP03-112EN.png";
    case "Actress Feria":
      return "../textures/BP03-114EN.png";
    case "Humpty Dumpty":
      return "../textures/BP03-115EN.png";
    case "Winged Inversion":
      return "../textures/BP03-117EN.png";
    case "Angel of Darkness":
      return "../textures/BP03-118EN.png";
    case "Harbringer of the Night":
      return "../textures/BP03-119EN.png";
    case "Eggsplosion":
      return "../textures/BP03-121EN.png";
    case "Tazuna Hayakawa [Traccen Reception]":
      return "../textures/CSD01-007EN.png";
    case "Riko Kashimoto":
      return "../textures/CSD01-030EN.png";
    case "Aoi Kiryuin":
      return "../textures/CSD01-031EN.png";
    case "Silence Suzuka":
      return "../textures/CP01-SP01EN.png";
    case "Smart Falcon":
      return "../textures/CP01-SP03EN.png";
    case "Gold City":
      return "../textures/CP01-004EN.png";
    case "Eat Fast! Yum Fast!":
      return "../textures/CP01-005EN.png";
    case "Shinko Windy":
      return "../textures/CP01-006EN.png";
    case "Eishin Flash":
      return "../textures/CP01-007EN.png";
    case "Systematic Squats":
      return "../textures/CP01-008EN.png";
    case "Marvelous Sunday":
      return "../textures/CP01-009EN.png";
    case "Yukino Bijin":
      return "../textures/CP01-010EN.png";
    case "Ines Fujin":
      return "../textures/CP01-011EN.png";
    case "Taiki Shuttle":
      return "../textures/CP01-012EN.png";
    case "Haru Urara":
      return "../textures/CP01-013EN.png";
    case "Tokai Teio":
      return "../textures/CP01-SP04EN.png";
    case "Narita Brian":
      return "../textures/CP01-SP06EN.png";
    case "Winning Ticket":
      return "../textures/CP01-017EN.png";
    case "Outrunning the Encroaching Heat":
      return "../textures/CP01-018EN.png";
    case "Air Groove":
      return "../textures/CP01-019EN.png";
    case "Hishi Amazon":
      return "../textures/CP01-020EN.png";
    case "Trial Initiation":
      return "../textures/CP01-021EN.png";
    case "Sirius Symboli":
      return "../textures/CP01-022EN.png";
    case "Narita Taishin":
      return "../textures/CP01-023EN.png";
    case "Symboli Rudolf":
      return "../textures/CP01-024EN.png";
    case "Biko Pegasus":
      return "../textures/CP01-025EN.png";
    case "Fuji Kiseki":
      return "../textures/CP01-026EN.png";
    case "Agnes Tachyon":
      return "../textures/CP01-SP07EN.png";
    case "Daiwa Scarlet":
      return "../textures/CP01-SP09EN.png";
    case "Vodka":
      return "../textures/CP01-030EN.png";
    case "Make! Some! NOISE!":
      return "../textures/CP01-031EN.png";
    case "Zenno Rob Roy":
      return "../textures/CP01-032EN.png";
    case "Agens Digital":
      return "../textures/CP01-033EN.png";
    case "Lamplit Training of a Witch-to-Be":
      return "../textures/CP01-034EN.png";
    case "Admire Vega":
      return "../textures/CP01-035EN.png";
    case "Kawakami Princess":
      return "../textures/CP01-036EN.png";
    case "Nakayama Festa":
      return "../textures/CP01-037EN.png";
    case "Tosen Jordan":
      return "../textures/CP01-038EN.png";
    case "Narita Top Road":
      return "../textures/CP01-039EN.png";
    case "Special Week":
      return "../textures/CP01-SP10EN.png";
    case "Oguri Cap":
      return "../textures/CP01-SP12EN.png";
    case "Seiun Sky":
      return "../textures/CP01-043EN.png";
    case "Champion's Passion":
      return "../textures/CP01-044EN.png";
    case "King Halo":
      return "../textures/CP01-045EN.png";
    case "Tamamo Cross":
      return "../textures/CP01-046EN.png";
    case "Flowers for You":
      return "../textures/CP01-047EN.png";
    case "Bamboo Memory":
      return "../textures/CP01-048EN.png";
    case "Yaeno Muteki":
      return "../textures/CP01-049EN.png";
    case "Grass Wonder":
      return "../textures/CP01-050EN.png";
    case "Super Creek":
      return "../textures/CP01-051EN.png";
    case "Hishi Akebono":
      return "../textures/CP01-052EN.png";
    case "Maruzensky":
      return "../textures/CP01-SP13EN.png";
    case "Rice Shower":
      return "../textures/CP01-SP15EN.png";
    case "Nice Nature":
      return "../textures/CP01-056EN.png";

    case "7 More Centimeters":
      return "../textures/CP01-057EN.png";
    case "Fine Motion":
      return "../textures/CP01-058EN.png";
    case "Mayano Top Gun":
      return "../textures/CP01-059EN.png";
    case "My Solo Drawn to Raindrop Drums":
      return "../textures/CP01-060EN.png";
    case "Curren Chan":
      return "../textures/CP01-061EN.png";
    case "Twin Turbo":
      return "../textures/CP01-062EN.png";
    case "Sakura Chiyono O":
      return "../textures/CP01-063EN.png";
    case "Seeking the Pearl":
      return "../textures/CP01-064EN.png";
    case "Matikanetannhauser":
      return "../textures/CP01-065EN.png";
    case "Mejiro McQueen":
      return "../textures/CP01-SP16EN.png";
    case "Gold Ship":
      return "../textures/CP01-SP18EN.png";
    case "Ikuno Dictus":
      return "../textures/CP01-069EN.png";
    case "The Will to Overtake":
      return "../textures/CP01-070EN.png";
    case "Meisho Doto":
      return "../textures/CP01-071EN.png";
    case "Mejiro Ryan":
      return "../textures/CP01-072EN.png";
    case "Fate's Forecast":
      return "../textures/CP01-073EN.png";
    case "Inari One":
      return "../textures/CP01-074EN.png";
    case "Mejiro Dober":
      return "../textures/CP01-075EN.png";
    case "Mejiro Ardan":
      return "../textures/CP01-076EN.png";
    case "Mejiro Palmer":
      return "../textures/CP01-077EN.png";
    case "T.M Opera O":
      return "../textures/CP01-078EN.png";
    case "Riko Kashimoto [Planned Perfection]":
      return "../textures/CP01-SP19EN.png";
    case "Close Knit Ambitions":
      return "../textures/CP01-080EN.png";
    case "Take a Jab!":
      return "../textures/CP01-081EN.png";
    case "Aoi Kiryuin [Trainers' Teamwork]":
      return "../textures/CP01-082EN.png";
    case "Sasami Anshinzawa":
      return "../textures/CP01-083EN.png";
    case "Tazuna Hayakawa":
      return "../textures/CP01-084EN.png";

    case "Crystalia Tia":
      return "../textures/BP02-001EN.png";
    case "White Wolf of Eldwood":
      return "../textures/BP02-003EN.png";
    case "Elf Girl Liza":
      return "../textures/BP02-004EN.png";
    case "Elf Knight Cynthia":
      return "../textures/BP02-005EN.png";
    case "Grand Archer Seiwyn":
      return "../textures/BP02-006EN.png";
    case "Crystalia Lily":
      return "../textures/BP02-008EN.png";
    case "Baalt King of the Elves":
      return "../textures/BP02-010EN.png";
    case "Elven Archery":
      return "../textures/BP02-011EN.png";
    case "Dwarf Perfumer":
      return "../textures/BP02-012EN.png";
    case "Elf Healer":
      return "../textures/BP02-013EN.png";
    case "Forest Gigas":
      return "../textures/BP02-014EN.png";
    case "Elf Bard":
      return "../textures/BP02-016EN.png";
    case "Rose Deer":
      return "../textures/BP02-017EN.png";
    case "Albert Levin Saber":
      return "../textures/BP02-018EN.png";
    case "Alexander":
      return "../textures/BP02-020EN.png";
    case "Amelia, Silver Paladin":
      return "../textures/BP02-U02EN.png";
    case "Leonidas":
      return "../textures/BP02-022EN.png";
    case "White Paladin":
      return "../textures/BP02-024EN.png";
    case "Jeno, Levin Vanguard":
      return "../textures/BP02-025EN.png";
    case "Yurius, Levin Duke":
      return "../textures/BP02-027EN.png";
    case "Whole-Souled Swing":
      return "../textures/BP02-028EN.png";
    case "Swift Infiltrator":
      return "../textures/BP02-029EN.png";
    case "Samurai":
      return "../textures/BP02-030EN.png";
    case "Avant Blader":
      return "../textures/BP02-031EN.png";
    case "Flame Soldier":
      return "../textures/BP02-033EN.png";
    case "Gunner Maid Seria":
      return "../textures/BP02-034EN.png";
    case "Daria Dimensional Witch":
      return "../textures/BP02-035EN.png";
    case "Sun Oracle Pascale":
      return "../textures/BP02-037EN.png";
    case "Anne, Belle oF Mysteria":
      return "../textures/BP02-038EN.png";
    case "Grea the Dragonborn":
      return "../textures/BP02-040EN.png";
    case "Rimewind":
      return "../textures/BP02-041EN.png";
    case "Remi & Rami, Witchy Duo":
      return "../textures/BP02-042EN.png";
    case "Shadow Witch":
      return "../textures/BP02-044EN.png";
    case "Multipart Expirement":
      return "../textures/BP02-045EN.png";
    case "Craig, Wizard of Mysteria":
      return "../textures/BP02-046EN.png";
    case "Grand Gargoyle":
      return "../textures/BP02-048EN.png";
    case "Witchbolt":
      return "../textures/BP02-049EN.png";
    case "Magical Strategy":
      return "../textures/BP02-050EN.png";
    case "Red-Hot Ritual":
      return "../textures/BP02-051EN.png";
    case "Imperial Dragoon":
      return "../textures/BP02-052EN.png";
    case "Dragonsong Flute":
      return "../textures/BP02-054EN.png";
    case "Neptune":
      return "../textures/BP02-055EN.png";
    case "Draconic Fervor":
      return "../textures/BP02-057EN.png";
    case "Polyphonic Roar":
      return "../textures/BP02-058EN.png";
    case "Siegfried":
      return "../textures/BP02-059EN.png";
    case "Transmogrified Wyrm":
      return "../textures/BP02-061EN.png";
    case "Dracomancer's Rites":
      return "../textures/BP02-062EN.png";
    case "Wildfang Dragonewt":
      return "../textures/BP02-063EN.png";
    case "Mushussu":
      return "../textures/BP02-064EN.png";
    case "Dragontamer":
      return "../textures/BP02-065EN.png";
    case "Twin-Headed Dragon":
      return "../textures/BP02-067EN.png";
    case "Draconic Armor":
      return "../textures/BP02-068EN.png";
    case "Vania, Vampire Princess":
      return "../textures/BP02-U05EN.png";
    case "Soul Dealer":
      return "../textures/BP02-070EN.png";
    case "Underworld Watchman Khawy":
      return "../textures/BP02-072EN.png";
    case "Azazel":
      return "../textures/BP02-073EN.png";
    case "Vampiric Fortress":
      return "../textures/BP02-075EN.png";
    case "Veight, Vampire Noble":
      return "../textures/BP02-076EN.png";
    case "Trick Dullahan":
      return "../textures/BP02-078EN.png";
    case "Precious Bloodfangs":
      return "../textures/BP02-079EN.png";

    case "Mini Soul Devil":
      return "../textures/BP02-080EN.png";
    case "Moriana the Bejeweled":
      return "../textures/BP02-081EN.png";
    case "Demonic Hedonist":
      return "../textures/BP02-082EN.png";
    case "Bone Chimera":
      return "../textures/BP02-084EN.png";
    case "Necrocarnival":
      return "../textures/BP02-085EN.png";
    case "Heavenly Aegis":
      return "../textures/BP02-086EN.png";
    case "Enstatued Seraph":
      return "../textures/BP02-088EN.png";
    case "Kaguya":
      return "../textures/BP02-089EN.png";
    case "Tribunal of Good and Evil":
      return "../textures/BP02-091EN.png";
    case "Elana's Prayer":
      return "../textures/BP02-092EN.png";

    case "Radiance Angel":
      return "../textures/BP02-093EN.png";
    case "Saphire Priestess":
      return "../textures/BP02-095EN.png";
    case "Beastcall Aria":
      return "../textures/BP02-096EN.png";
    case "Frog Cleric":
      return "../textures/BP02-097EN.png";
    case "Sky Sprite":
      return "../textures/BP02-098EN.png";
    case "Soul Collector":
      return "../textures/BP02-099EN.png";
    case "Sledgehammer Exorcist":
      return "../textures/BP02-101EN.png";
    case "Emerald Maiden":
      return "../textures/BP02-102EN.png";
    case "Dark Angel Olivia":
      return "../textures/BP02-103EN.png";
    case "Bahamut":
      return "../textures/BP02-104EN.png";
    case "Demonic Simulacrum":
      return "../textures/BP02-106EN.png";
    case "Archangel Reina":
      return "../textures/BP02-107EN.png";
    case "Surefire Bullet":
      return "../textures/BP02-109EN.png";
    case "Unicorn Dancer Unicorn":
      return "../textures/BP02-110EN.png";
    case "Gourmet Emperor Khaiza":
      return "../textures/BP02-112EN.png";
    case "Call of Cocytus":
      return "../textures/BP02-113EN.png";
    case "Hamsa":
      return "../textures/BP02-114EN.png";
    case "Sektor":
      return "../textures/BP02-115EN.png";
    case "Dance of Death":
      return "../textures/BP02-117EN.png";
    case "Skullfane":
      return "../textures/SD06-001EN.png";
    case "Hare of Illusions":
      return "../textures/SD06-002EN.png";
    case "Priest of the Cudgel":
      return "../textures/SD06-003EN.png";
    case "Acolyte's Light":
      return "../textures/SD06-005EN.png";
    case "Dual Flames":
      return "../textures/SD06-006EN.png";
    case "Ardent Nun":
      return "../textures/SD06-009EN.png";
    case "Guardian Nun":
      return "../textures/SD06-011EN.png";
    case "Pinion Prayer":
      return "../textures/SD06-015EN.png";
    case "Beastly Vow":
      return "../textures/SD06-016EN.png";
    case "Queen Vampire":
      return "../textures/SD05-001EN.png";
    case "Alucard":
      return "../textures/SD05-002EN.png";
    case "Playful Necromancer":
      return "../textures/SD05-003EN.png";
    case "Midnight Vampire":
      return "../textures/SD05-005EN.png";
    case "Night Horde":
      return "../textures/SD05-006EN.png";
    case "Lesser Mummy":
      return "../textures/SD05-010EN.png";
    case "Lilith":
      return "../textures/SD05-012EN.png";
    case "Undying Resentment":
      return "../textures/SD05-015EN.png";
    case "Summon Bloodkin":
      return "../textures/SD05-016EN.png";
    case "Fafnir":
      return "../textures/SD04-001EN.png";
    case "Dragon Oracle":
      return "../textures/PR-235EN.png";
    case "Dragon Warrior":
      return "../textures/SD04-003EN.png";
    case "Dragonewt Princess":
      return "../textures/SD04-005EN.png";
    case "Dragonguard":
      return "../textures/SD04-006EN.png";
    case "Roc":
      return "../textures/SD04-009EN.png";
    case "Glint Dragon":
      return "../textures/SD04-011EN.png";
    case "Dragonrider":
      return "../textures/SD04-012EN.png";
    case "Seabrand Dragon":
      return "../textures/SD04-014EN.png";
    case "Mythril Golem":
      return "../textures/SD03-001EN.png";
    case "Rune Blade Summoner":
      return "../textures/SD03-002EN.png";
    case "Demonflame Mage":
      return "../textures/SD03-003EN.png";
    case "Insight":
      return "../textures/SD03-005EN.png";
    case "Fire Chain":
      return "../textures/SD03-006EN.png";
    case "Penguin Wizard":
      return "../textures/SD03-008EN.png";
    case "Sammy Wizard's Apprentice":
      return "../textures/SD03-010EN.png";
    case "Magic Missle":
      return "../textures/SD03-015EN.png";
    case "Conjure Golem":
      return "../textures/SD03-016EN.png";
    case "Tsubasa":
      return "../textures/SD02-001EN.png";
    case "Latham, Vanguard Captain":
      return "../textures/SD02-002EN.png";
    case "Floral Fencer":
      return "../textures/SD02-003EN.png";
    case "Moonlight Assassin":
      return "../textures/SD02-005EN.png";
    case "White General":
      return "../textures/SD02-006EN.png";
    case "Fencer":
      return "../textures/SD02-009EN.png";
    case "Oathless Knight":
      return "../textures/SD02-010EN.png";
    case "Quickblader":
      return "../textures/SD02-012EN.png";
    case "Unbridled Fury":
      return "../textures/SD02-016EN.png";
    case "Aria, Fairy Princess":
      return "../textures/SD01-001EN.png";
    case "Titania's Sanctuary":
      return "../textures/SD01-002EN.png";
    case "Rose Gardener":
      return "../textures/SD01-003EN.png";
    case "Waltzing Fairy":
      return "../textures/SD01-005EN.png";
    case "Fairy Caster":
      return "../textures/SD01-006EN.png";
    case "Treant":
      return "../textures/SD01-009EN.png";
    case "Water Fairy":
      return "../textures/SD01-011EN.png";
    case "Elf Wanderer":
      return "../textures/SD01-013EN.png";
    case "Sylvan Justice":
      return "../textures/SD01-016EN.png";
    case "Rose Queen":
      return "../textures/BP01-U01EN.png";
    case "Ancient Elf":
      return "../textures/BP01-002EN.png";
    case "Rhinoceroach":
      return "../textures/BP01-004EN.png";
    case "Robin Hood":
      return "../textures/BP01-006EN.png";
    case "Silver Bolt":
      return "../textures/BP01-007EN.png";
    case "Homecoming":
      return "../textures/BP01-008EN.png";
    case "Elven Princess Mage":
      return "../textures/BP01-009EN.png";
    case "Blessed Fairy Dancer":
      return "../textures/BP01-011EN.png";
    case "Elf Child May":
      return "../textures/BP01-012EN.png";
    case "Fairy Beast":
      return "../textures/BP01-013EN.png";
    case "Noble Fairy":
      return "../textures/BP01-014EN.png";
    case "Nature's Guidance":
      return "../textures/BP01-015EN.png";
    case "Harvest Festival":
      return "../textures/BP01-016EN.png";
    case "Elf Metallurgist":
      return "../textures/BP01-017EN.png";
    case "Archer":
      return "../textures/BP01-018EN.png";
    case "Fairy Whisperer":
      return "../textures/BP01-020EN.png";
    case "Okami":
      return "../textures/BP01-021EN.png";
    case "Mana Elk":
      return "../textures/BP01-022EN.png";
    case "Fairy Circle":
      return "../textures/BP01-023EN.png";
    case "Woodkin Curse":
      return "../textures/BP01-024EN.png";
    case "Woodland Refuge":
      return "../textures/BP01-025EN.png";
    case "Sea Queen Otohime":
      return "../textures/BP01-026EN.png";
    case "Aurelia, Regal Saber":
      return "../textures/BP01-028EN.png";
    case "Shadowed Assassin":
      return "../textures/BP01-029EN.png";
    case "Frontguard General":
      return "../textures/BP01-031EN.png";
    case "Alwida's Command":
      return "../textures/BP01-032EN.png";
    case "Royal Banner":
      return "../textures/BP01-033EN.png";
    case "Maid Leader":
      return "../textures/BP01-034EN.png";
    case "Gemstaff Commander":
      return "../textures/BP01-036EN.png";
    case "Sage Commander":
      return "../textures/BP01-037EN.png";
    case "Swordsman":
      return "../textures/BP01-038EN.png";
    case "Pompous Princess":
      return "../textures/BP01-039EN.png";
    case "Ninja Master":
      return "../textures/BP01-040EN.png";
    case "Arthurian Light":
      return "../textures/BP01-041EN.png";
    case "Ninja Trainee":
      return "../textures/BP01-042EN.png";
    case "Fervid Soldier":
      return "../textures/BP01-043EN.png";
    case "Luminous Knight":
      return "../textures/BP01-045EN.png";
    case "Veteran Lancer":
      return "../textures/BP01-046EN.png";
    case "Navy Lieutenant":
      return "../textures/BP01-047EN.png";
    case "Novice Trooper":
      return "../textures/BP01-048EN.png";
    case "Forge Weaponry":
      return "../textures/BP01-049EN.png";
    case "Onslaught":
      return "../textures/BP01-050EN.png";
    case "Arch Summoner Erasmus":
      return "../textures/BP01-051EN.png";
    case "Merlin":
      return "../textures/BP01-052EN.png";
    case "Ancient Alchemist":
      return "../textures/BP01-054EN.png";
    case "Arcane Enlightenment":
      return "../textures/BP01-056EN.png";
    case "Dimension Shift":
      return "../textures/BP01-057EN.png";
    case "Juno's Secret Laboratory":
      return "../textures/BP01-U03EN.png";
    case "Spectral Wizard":
      return "../textures/BP01-059EN.png";
    case "Flame Destroyer":
      return "../textures/BP01-061EN.png";
    case "Dragonbond Mage":
      return "../textures/BP01-062EN.png";
    case "Golem Protection":
      return "../textures/BP01-063EN.png";
    case "Alchemical Lore":
      return "../textures/BP01-064EN.png";
    case "Fate's Hand":
      return "../textures/BP01-065EN.png";
    case "Price of Magic":
      return "../textures/BP01-066EN.png";
    case "Runic Guardian":
      return "../textures/BP01-067EN.png";
    case "Crafty Warlock":
      return "../textures/BP01-068EN.png";
    case "Lightning Shooter":
      return "../textures/BP01-070EN.png";
    case "Wind Blast":
      return "../textures/BP01-071EN.png";
    case "Sorcery Cache":
      return "../textures/BP01-072EN.png";
    case "Fiery Embrace":
      return "../textures/BP01-073EN.png";
    case "Alchemist's Workshop":
      return "../textures/BP01-074EN.png";

    case "Teachings of Creation":
      return "../textures/BP01-075EN.png";
    case "Dark Dragoon Forte":
      return "../textures/BP01-076EN.png";
    case "Aiela, Dragon Knight":
      return "../textures/BP01-P16EN.png";
    case "Zirnitra":
      return "../textures/BP01-078EN.png";
    case "Genesis Dragon":
      return "../textures/BP01-080EN.png";
    case "Shapeshifting Mage":
      return "../textures/BP01-081EN.png";
    case "Phoenix Roost":
      return "../textures/BP01-083EN.png";
    case "Wyvern Cavalier":
      return "../textures/BP01-084EN.png";
    case "Dragonewt Scholar":
      return "../textures/BP01-085EN.png";
    case "Shenlong":
      return "../textures/BP01-086EN.png";
    case "Imprisoned Dragon":
      return "../textures/BP01-088EN.png";
    case "Conflagration":
      return "../textures/BP01-089EN.png";
    case "Serpent's Wrath":
      return "../textures/BP01-090EN.png";
    case "Wyrm Spire":
      return "../textures/BP01-091EN.png";

    case "Ivory Dragon":
      return "../textures/BP01-092EN.png";
    case "Fire Lizard":
      return "../textures/BP01-094EN.png";
    case "Ace Dragoon":
      return "../textures/BP01-095EN.png";
    case "Mist Dragon":
      return "../textures/BP01-096EN.png";
    case "Dread Dragon":
      return "../textures/BP01-097EN.png";
    case "Blazing Breath":
      return "../textures/BP01-098EN.png";
    case "Dragon Wings":
      return "../textures/BP01-099EN.png";
    case "Dragon Emissary":
      return "../textures/BP01-100EN.png";
    case "Cerberus":
      return "../textures/BP01-101EN.png";
    case "Lord Atomy":
      return "../textures/BP01-103EN.png";
    case "Medusa":
      return "../textures/BP01-U05EN.png";

    case "Righteous Devil":
      return "../textures/BP01-105EN.png";
    case "Mordecai the Duelist":
      return "../textures/BP01-107EN.png";
    case "Dire Bond":
      return "../textures/BP01-108EN.png";
    case "Hell's Unleasher":
      return "../textures/BP01-109EN.png";
    case "Crazed Executioner":
      return "../textures/BP01-110EN.png";
    case "Dark Summoner":
      return "../textures/BP01-112EN.png";
    case "Dark General":
      return "../textures/BP01-113EN.png";
    case "Phantom Howl":
      return "../textures/BP01-114EN.png";
    case "Death's Breath":
      return "../textures/BP01-115EN.png";
    case "Soul Conversion":
      return "../textures/BP01-116EN.png";
    case "Skeleton Fighter":
      return "../textures/BP01-117EN.png";
    case "Ambling Wraith":
      return "../textures/BP01-118EN.png";
    case "Spectre":
      return "../textures/BP01-119EN.png";
    case "Spartoi Sergeant":
      return "../textures/BP01-120EN.png";
    case "Rabbit Necromancer":
      return "../textures/BP01-121EN.png";
    case "Wardrobe Raider":
      return "../textures/BP01-122EN.png";
    case "Undead King":
      return "../textures/BP01-124EN.png";
    case "Razory Claw":
      return "../textures/BP01-125EN.png";
    case "Moon Al-mi'raj":
      return "../textures/BP01-126EN.png";
    case "Jeanne d'Arc":
      return "../textures/BP01-127EN.png";
    case "Arch Priestess Laelia":
      return "../textures/BP01-129EN.png";
    case "Themis's Decree":
      return "../textures/BP01-131EN.png";
    case "Chorus of Prayer":
      return "../textures/BP01-132EN.png";
    case "Sacred Plea":
      return "../textures/BP01-133EN.png";
    case "Temple Defender":
      return "../textures/BP01-134EN.png";
    case "Prism Priestess":
      return "../textures/BP01-135EN.png";
    case "Cleric Lancer":
      return "../textures/BP01-137EN.png";
    case "Shrine Knight Maiden":
      return "../textures/BP01-138EN.png";
    case "Blackened Scripture":
      return "../textures/BP01-139EN.png";
    case "Dark Offering":
      return "../textures/BP01-140EN.png";
    case "Holy Sentinel":
      return "../textures/BP01-141EN.png";
    case "Cruel Priestess":
      return "../textures/BP01-142EN.png";
    case "Sister Initiate":
      return "../textures/BP01-143EN.png";
    case "Mainyu":
      return "../textures/BP01-144EN.png";
    case "Snake Priestess":
      return "../textures/BP01-146EN.png";
    case "Curate":
      return "../textures/BP01-147EN.png";
    case "Hallowed Dogma":
      return "../textures/BP01-148EN.png";
    case "Guardian Sun":
      return "../textures/BP01-149EN.png";
    case "Death Sentence":
      return "../textures/BP01-150EN.png";
    case "Gabriel":
      return "../textures/BP01-U07EN.png";
    case "Lucifer":
      return "../textures/BP01-152EN.png";
    case "Flame and Glass":
      return "../textures/BP01-154EN.png";

    case "Urd":
      return "../textures/BP01-155EN.png";
    case "Wind God":
      return "../textures/BP01-157EN.png";
    case "Gilgamesh":
      return "../textures/BP01-158EN.png";
    case "Bellringer Angel":
      return "../textures/BP01-159EN.png";
    case "Altered Fate":
      return "../textures/BP01-161EN.png";
    case "Path to Purgatory":
      return "../textures/BP01-162EN.png";
    case "Lizardman":
      return "../textures/BP01-163EN.png";
    case "Goblinmount Demon":
      return "../textures/BP01-164EN.png";
    case "Harnessed Flame":
      return "../textures/BP01-166EN.png";
    case "Harnessed Glass":
      return "../textures/BP01-167EN.png";
    case "Demonic Strike":
      return "../textures/BP01-168EN.png";
    case "Execution":
      return "../textures/BP01-169EN.png";
    case "Trail of Light":
      return "../textures/PR-020EN.png";
    case "Goblin":
      return "../textures/BP01-171EN.png";
    case "Fighter":
      return "../textures/BP01-173EN.png";
    case "Goliath":
      return "../textures/BP01-174EN.png";
    case "Angelic Sword Maiden":
      return "../textures/BP01-176EN.png";
    case "Healing Angel":
      return "../textures/BP01-177EN.png";
    case "Angelic Snipe":
      return "../textures/PR-017EN.png";
    case "Angelic Barrage":
      return "../textures/PR-018EN.png";

    case "White Vanara Evolved":
      return "../textures/bp09-003.png";
    case "Paula, Gentle Warmth":
      return "../textures/bp09-005.png";
    case "Paula, Passionate Warmth":
      return "../textures/bp09-005_ura.png";
    case "Storied Falconer Evolved":
      return "../textures/bp09-009.png";
    case "Grasshopper Conductor Evolved":
      return "../textures/bp09-013.png";

    case "Celia, Hope's Strategist":
      return "../textures/bp09-019.png";
    case "Celia, Despair's Messenger":
      return "../textures/bp09-019_ura.png";
    case "Innocent Princess Prim Evolved":
      return "../textures/bp09-022.png";
    case "Dario, Demon Count Evolved":
      return "../textures/bp09-026.png";
    case "Axe Princess Evolved":
      return "../textures/bp09-030.png";
    case "Ceridwen, Eternity Hunter Evolved":
      return "../textures/bp09-036.png";
    case "Mysterian Whitewyrm":
      return "../textures/bp09-039.png";
    case "Mysterian Blackwyrm":
      return "../textures/bp09-039_ura.png";
    case "Bergent, Onion Patchmaster Evolved":
      return "../textures/bp09-043.png";
    case "Witch of Foresight Evolved":
      return "../textures/bp09-047.png";
    case "Grea, Mysterian Dragoness Evolved":
      return "../textures/dsd01a-003.png";
    case "Mysterian Summoner Vayle Evolved":
      return "../textures/dsd01a-011.png";
    case "Jerva of Draconic Mail Evolved":
      return "../textures/bp09-u04.png";
    case "Virtuous Lindworm":
      return "../textures/bp09-056.png";
    case "Iniquitous Lindworm":
      return "../textures/bp09-056_ura.png";
    case "Dragoncleaver Roy Evolved":
      return "../textures/bp09-060.png";
    case "Heroic Dragonslayer Evolved":
      return "../textures/bp09-064.png";
    case "Romelia, Lightning Striker Evolved":
      return "../textures/dsd01b-u01.png";
    case "Katakura Kojuro Evolved":
      return "../textures/dsd01b-011.png";
    case "Kind Queen Vania":
      return "../textures/bp09-070.png";
    case "Blood Queen Vania":
      return "../textures/bp09-070_ura.png";
    case "Oldblood King Evolved":
      return "../textures/bp09-073.png";
    case "Big Soul Hunter Evolved":
      return "../textures/bp09-077.png";
    case "Orator of the Bones Evolved":
      return "../textures/bp09-081.png";
    case "Jeanne, Beacon of Salvation Evolved":
      return "../textures/bp09-u06.png";
    case "Cerynelan Lighthind":
      return "../textures/bp09-090.png";
    case "Cerynelan Darkhind":
      return "../textures/bp09-090_ura.png";
    case "Jeweled Priestess Evolved":
      return "../textures/bp09-094.png";
    case "Lycaon Evolved":
      return "../textures/bp09-098.png";
    case "Marduk Evolved":
      return "../textures/bp09-u07.png";
    case "Paradise Vanguard Evolved":
      return "../textures/bp09-107.png";
    case "Suttungr Evolved":
      return "../textures/bp09-111.png";
    case "Valkyrie of Chaos Evolved":
      return "../textures/bp09-115.png";

    case "Orchis, Resolute Puppet":
      return "../textures/BP08-003ENfront.png";
    case "Orchis, Vengeful Puppet":
      return "../textures/BP08-003ENback.png";
    case "Zwei, Murderous Puppet Evolved":
      return "../textures/BP08-005EN.png";
    case "Michelle, the Mind Reader Evolved":
      return "../textures/BP08-009EN.png";
    case "Insane Dark Elf Evolved":
      return "../textures/BP08-014EN.png";
    case "Dionne, Dancing Blade Evolved":
      return "../textures/BP08-U02EN.png";
    case "Roland the Incorruptible Evolved":
      return "../textures/BP08-022EN.png";
    case "Azord, Duke of the Mists Evolved":
      return "../textures/BP08-026EN.png";
    case "Mana Pistol Merc Evolved":
      return "../textures/BP08-033EN.png";
    case "Sweet-Tooth Medusa Evolved":
      return "../textures/BP08-036EN.png";
    case "Unbodied Witch Evolved":
      return "../textures/BP08-039EN.png";
    case "Elusa, Magic Wunderkind Evolved":
      return "../textures/BP08-043EN.png";
    case "Rabbit Mage Evolved":
      return "../textures/BP08-049EN.png";
    case "Azi Dahaka Evolved":
      return "../textures/BP08-U04EN.png";
    case "Annerose Evolved":
      return "../textures/BP08-056EN.png";
    case "Elios, Loyal Dragoon Evolved":
      return "../textures/BP08-060EN.png";
    case "Geovore Evolved":
      return "../textures/BP08-066EN.png";
    case "Crimson Rose Queen Evolved":
      return "../textures/BP08-070EN.png";
    case "Tartarus, the Tormentor Evolved":
      return "../textures/BP08-073EN.png";
    case "Chris Pumpkinhead Evolved":
      return "../textures/BP08-077EN.png";
    case "Marian the Mummy Evolved":
      return "../textures/BP08-082EN.png";
    case "Godsworn Alexiel Evolved":
      return "../textures/BP08-U06EN.png";
    case "Eidolon of Madness Evolved":
      return "../textures/BP08-090EN.png";
    case "Collete, Holy Gunner Evolved":
      return "../textures/BP08-094EN.png";
    case "Temple Windbear Evolved":
      return "../textures/BP08-100EN.png";

    case "Alterplane Arbiter Evolved":
      return "../textures/BP08-104EN.png";
    case "Sahaquiel Evolved":
      return "../textures/BP08-107EN.png";
    case "Reina, Evolution's Herald Evolved":
      return "../textures/BP08-111EN.png";
    case "High Enchantress Evolved":
      return "../textures/BP08-116EN.png";

    case "Ladica, the Stoneclaw Evolved":
      return "../textures/BP07-U01EN.png";
    case "Setus, the Beastblade Evolved":
      return "../textures/BP07-006EN.png";
    case "Blossom Spirit Evolved":
      return "../textures/BP07-009EN.png";
    case "Forest Hermit Evolved":
      return "../textures/BP07-015EN.png";

    case "Bayleon, Sovereign Light Evolved":
      return "../textures/BP07-019EN.png";
    case "Leod, the Crescent Blade Evolved":
      return "../textures/BP07-023EN.png";
    case "Troya, Thunder of Hagelberg Evolved":
      return "../textures/BP07-026EN.png";
    case "Dauntless Commander Evolved":
      return "../textures/BP07-032EN.png";

    case "Tetra, Sapphire Rebel Evolved":
      return "../textures/BP07-036EN.png";
    case "Eleanor, Cosmic Flower Evolved":
      return "../textures/BP07-040EN.png";
    case "Displacer Bot Evolved":
      return "../textures/BP07-043EN.png";
    case "Magiblade Witch Evolved":
      return "../textures/BP07-049EN.png";

    case "Valdain, Cursed Shadow Evolved":
      return "../textures/BP07-U04EN.png";
    case "Marion, Elegant Dragonewt Evolved":
      return "../textures/BP07-057EN.png";
    case "Hoarfrost Triceratops Evolved":
      return "../textures/BP07-061EN.png";
    case "Doting Dragoneer Evolved":
      return "../textures/BP07-066EN.png";

    case "Mono, Garnet Rebel Evolved":
      return "../textures/BP07-U05EN.png";
    case "Doublame, Duke and Dame Evolved":
      return "../textures/BP07-074EN.png";
    case "Nicola, Forbidden Strength Evolved":
      return "../textures/BP07-077EN.png";
    case "Berserk Demon Evolved":
      return "../textures/BP07-083EN.png";

    case "Limonia, Flawed Saint Evolved":
      return "../textures/BP07-087EN.png";
    case "Marione, Light of Balance Evolved":
      return "../textures/BP07-091EN.png";
    case "Robofalcon Evolved":
      return "../textures/BP07-095EN.png";
    case "Dark Bishop Evolved":
      return "../textures/BP07-099EN.png";

    case "Viridia Magna Evolved":
      return "../textures/BP07-105EN.png";
    case "Maisha, Hero of Purgation Evolved":
      return "../textures/BP07-109EN.png";
    case "Robogoblin Evolved":
      return "../textures/BP07-111EN.png";
    case "Aldis, Trendsetting Seraph Evolved":
      return "../textures/BP07-115EN.png";

    case "Momoka Sakurai Evolved":
      return "../textures/CSD02a-006EN.png";
    case "Akiha Ikebukuro Evolved":
      return "../textures/CSD02a-008EN.png";
    case "Nene Kurihara Evolved":
      return "../textures/CSD02a-010EN.png";

    case "Yukimi Sajo Evolved":
      return "../textures/CSD02b-006EN.png";
    case "Kako Takafuji Evolved":
      return "../textures/CSD02b-008EN.png";
    case "Seira Mizuki Evolved":
      return "../textures/CSD02b-011EN.png";

    case "Suzuho Ueda Evolved":
      return "../textures/CSD02c-006EN.png";
    case "Miria Akagi Evolved":
      return "../textures/CSD02c-008EN.png";
    case "Kumiko Matsuyama Evolved":
      return "../textures/CSD02c-011EN.png";

    case "Aiko Takamori Evolved":
      return "../textures/CP02-002EN.png";
    case "Yuzu Kitami Evolved":
      return "../textures/CP02-005EN.png";
    case "Yumi Aiba Evolved":
      return "../textures/CP02-010EN.png";
    case "Kana Imai Evolved":
      return "../textures/CP02-015EN.png";

    case "Kyoko Igarashi Evolved":
      return "../textures/CP02-U03EN.png";
    case "Uzuki Shimamura Evolved":
      return "../textures/CP02-024EN.png";
    case "Karen Hojo Evolved":
      return "../textures/CP02-027EN.png";
    case "Miho Kohinata Evolved":
      return "../textures/CP02-033EN.png";

    case "Shiki Ichinose Evolved":
      return "../textures/CP02-U05EN.png";
    case "Kanade Hayami Evolved":
      return "../textures/CP02-040EN.png";
    case "Hina Araki Evolved":
      return "../textures/CP02-043EN.png";
    case "Rika Jougasaki Evolved":
      return "../textures/CP02-048EN.png";

    case "Yui Ohtsuki Evolved":
      return "../textures/CP02-U07EN.png";
    case "Tsukasa Kiryu Evolved":
      return "../textures/CP02-058EN.png";
    case "Yuka Nakano Evolved":
      return "../textures/CP02-061EN.png";
    case "Noa Takamine Evolved":
      return "../textures/CP02-067EN.png";

    case "Ranko Kanzaki Evolved":
      return "../textures/CP02-U09bEN.png";
    case "Takumi Mukai Evolved":
      return "../textures/CP02-073EN.png";
    case "Aki Yamato Evolved":
      return "../textures/CP02-078EN.png";
    case "Syoko Hoshi Evolved":
      return "../textures/CP02-084EN.png";

    case "Shin Sato Evolved":
      return "../textures/CP02-U12aEN.png";
    case "Nana Abe Evolved":
      return "../textures/CP02-090EN.png";
    case "Haru Yuuki Evolved":
      return "../textures/CP02-095EN.png";
    case "Layla Evolved":
      return "../textures/CP02-100EN.png";

    case "Lymaga, Forest Champion Evolved":
      return "../textures/BP06-U01EN.png";
    case "Wildwood Matriarch Evolved":
      return "../textures/BP06-006EN.png";
    case "Woodland Cleaver Evolved":
      return "../textures/BP06-009EN.png";
    case "Mallet Monkey Evolved":
      return "../textures/BP06-015EN.png";
    case "Ralmia, Sonic Racer Evolved":
      return "../textures/BP06-U02EN.png";
    case "Hero of Antiquity Evolved":
      return "../textures/BP06-023EN.png";
    case "Quickdraw Maven Evolved":
      return "../textures/BP06-026EN.png";
    case "Samurai Outlaw Evolved":
      return "../textures/BP06-030EN.png";
    case "Mysteria, Magic Founder Evolved":
      return "../textures/BP06-037EN.png";
    case "Curse Crafter Evolved":
      return "../textures/BP06-039EN.png";
    case "Demoncaller Evolved":
      return "../textures/BP06-043EN.png";
    case "Charming Gentlemouse Evolved":
      return "../textures/BP06-048EN.png";
    case "Garyu, Surpreme Dragonkin Evolved":
      return "../textures/BP06-053EN.png";
    case "Wyrm God of the Skies Evolved":
      return "../textures/BP06-057EN.png";
    case "Ice Dancing Dragonewt Evolved":
      return "../textures/BP06-060EN.png";
    case "Dragonblader Evolved":
      return "../textures/BP06-065EN.png";
    case "Ginsetsu, Great Fox Evolved":
      return "../textures/BP06-U05EN.png";
    case "Shuten-Doji Evolved":
      return "../textures/BP06-073EN.png";
    case "Cougar Pelt Warrior Evolved":
      return "../textures/BP06-078EN.png";
    case "Zashiki-Warashi Evolved":
      return "../textures/BP06-081EN.png";
    case "Karula, Arts Master Evolved":
      return "../textures/BP06-U06EN.png";
    case "Phantom Blade Wielder Evolved":
      return "../textures/BP06-091EN.png";
    case "Holy Lancer Evolved":
      return "../textures/BP06-094EN.png";
    case "Barrage Brawler Evolved":
      return "../textures/BP06-100EN.png";
    case "Badb Catha Evolved":
      return "../textures/BP06-U07EN.png";
    case "Mithra, Daybreak Diety Evolved":
      return "../textures/BP06-107EN.png";
    case "Chaht, Ringside Announcer Evolved":
      return "../textures/BP06-111EN.png";
    case "Bazooka Goblins Evolved":
      return "../textures/BP06-116EN.png";

    case "Izudia, Omen of Unkilling Evolved":
      return "../textures/BP05-U01EN.png";
    case "Apostle of Unkilling Evolved":
      return "../textures/BP05-005EN.png";
    case "Noah, Vengeful Puppeteer Evolved":
      return "../textures/BP05-010EN.png";
    case "Flower Doll Evolved":
      return "../textures/BP05-015EN.png";
    case "Octrice, Omen of Usurpation Evolved":
      return "../textures/BP05-U02EN.png";
    case "Apostle of Usurpation Evolved":
      return "../textures/BP05-022EN.png";
    case "Geno, Machine Artisan Evolved":
      return "../textures/BP05-028EN.png";
    case "Captain Meteo Evolved":
      return "../textures/BP05-031EN.png";
    case "Raio, Omen of Truth Evolved":
      return "../textures/BP05-036EN.png";
    case "Apostle of Truth Evolved":
      return "../textures/BP05-039EN.png";
    case "Iron Staff Mechanic Evolved":
      return "../textures/BP05-043EN.png";
    case "Servant of Destruction Evolved":
      return "../textures/BP05-049EN.png";
    case "Galmieux, Omen of Disdain Evolved":
      return "../textures/BP05-U04EN.png";
    case "Apostle of Disdain Evolved":
      return "../textures/BP05-056EN.png";
    case "Cursed Stone Evolved":
      return "../textures/BP05-061EN.png";
    case "Airship Whale Evolved":
      return "../textures/BP05-066EN.png";
    case "Rulenye, Omen of Silence Evolved":
      return "../textures/BP05-071EN.png";
    case "Apostle of Silence Evolved":
      return "../textures/BP05-074EN.png";

    case "Masked Puppet Evolved":
      return "../textures/BP05-077EN.png";
    case "Servant of Lust Evolved":
      return "../textures/BP05-081EN.png";
    case "Marwynn, Omen of Repose Evolved":
      return "../textures/BP05-U06EN.png";
    case "Apostle of Repose Evolved":
      return "../textures/BP05-090EN.png";
    case "Unidentified Subject Evolved":
      return "../textures/BP05-095EN.png";
    case "Demon's Epitaph Evolved":
      return "../textures/BP05-099EN.png";
    case "Mjerrabaine, Omen of One Evolved":
      return "../textures/BP05-104EN.png";
    case "Apostle of Craving Evolved":
      return "../textures/BP05-107EN.png";
    case "Rosa, Mech Wing Maiden Evolved":
      return "../textures/BP05-111EN.png";
    case "Cat Cannoneer Evolved":
      return "../textures/BP05-115EN.png";

    case "Deepwood Anomaly Evolved":
      return "../textures/BP04-004EN.png";
    case "King Elephant Evolved":
      return "../textures/BP04-006EN.png";
    case "Sukuna, Brave and Small Evolved":
      return "../textures/BP04-011EN.png";
    case "Fita the Gentle Elf Evolved":
      return "../textures/BP04-016EN.png";
    case "Mars, Silent Flame General Evolved":
      return "../textures/BP04-021EN.png";
    case "Barbarossa Evolved":
      return "../textures/BP04-024EN.png";
    case "Shrouded Assassin Evolved":
      return "../textures/BP04-029EN.png";
    case "Tristan of the Round Table Evolved":
      return "../textures/BP04-036EN.png";
    case "Wordwielder Ginger Evolved":
      return "../textures/BP04-039EN.png";
    case "Europa Evolved":
      return "../textures/BP04-U03EN.png";
    case "Magic Illusionist Evolved":
      return "../textures/BP04-048EN.png";
    case "Dazzling Healer Evolved":
      return "../textures/BP04-052EN.png";
    case "Python Evolved":
      return "../textures/BP04-060EN.png";
    case "Lævateinn Dragon, Defense Form Evolved":
      return "../textures/BP04-061EN.png";
    case "Lævateinn Dragon, Blast Form Evolved":
      return "../textures/BP04-062EN.png";
    case "Star Phoenix Evolved":
      return "../textures/BP04-065EN.png";
    case "Cetus Evolved":
      return "../textures/BP04-070EN.png";
    case "Hippocampus Evolved":
      return "../textures/BP04-076EN.png";
    case "Howling Demon Evolved":
      return "../textures/BP04-081EN.png";
    case "Stheno Evolved":
      return "../textures/BP04-085EN.png";
    case "Fenrir Evolved":
      return "../textures/BP04-088EN.png";
    case "Frogbat Evolved":
      return "../textures/BP04-095EN.png";
    case "Dark Jeanne Evolved":
      return "../textures/BP04-100EN.png";
    case "Zoe, Princess of Goldenia Evolved":
      return "../textures/BP04-102EN.png";
    case "Star Priestess Evolved":
      return "../textures/BP04-107EN.png";
    case "Mist Shaman Evolved":
      return "../textures/BP04-113EN.png";
    case "Israfil Evolved":
      return "../textures/BP04-U07EN.png";
    case "Grimnir, War Cyclone Evolved":
      return "../textures/BP04-120EN.png";
    case "Goblin Princess Evolved":
      return "../textures/BP04-125EN.png";
    case "Owlcat Evolved":
      return "../textures/BP04-128EN.png";

    case "Cosmos Fang Evolved":
      return "../textures/BP03-003EN.png";
    case "Slade Blossoming Wolf Evolved":
      return "../textures/BP03-006EN.png";
    case "Gerbera Bear Evolved":
      return "../textures/BP03-010EN.png";
    case "Tweedle Dum, Tweedle Dee Evolved":
      return "../textures/BP03-015EN.png";
    case "Valiant Fencer Evolved":
      return "../textures/BP03-020EN.png";
    case "Amerro, Spear Knight Evolved":
      return "../textures/BP03-023EN.png";
    case "Mach Knight Evolved":
      return "../textures/BP03-027EN.png";
    case "Bladed Hedgehog Evolved":
      return "../textures/BP03-032EN.png";
    case "Mystic King Evolved":
      return "../textures/BP03-038EN.png";
    case "Milady, Mystic Queen Evolved":
      return "../textures/BP03-041EN.png";
    case "Magical Knight Evolved":
      return "../textures/BP03-045EN.png";
    case "Witch of Sweets Evolved":
      return "../textures/BP03-049EN.png";
    case "Lævateinn Dragon Evolved":
      return "../textures/BP03-057EN.png";
    case "Lævateinn Dragon Attack Form Evolved":
      return "../textures/BP03-058EN.png";
    case "Draconir, Knuckle Dragon Evolved":
      return "../textures/BP03-061EN.png";
    case "Hammer Dragonewt Evolved":
      return "../textures/BP03-065EN.png";
    case "Dragon Summoner Evolved":
      return "../textures/BP03-070EN.png";
    case "Masquerade Ghost Evolved":
      return "../textures/BP03-075EN.png";
    case "Baccherus, Peppy Ghostie Evolved":
      return "../textures/BP03-079EN.png";
    case "Trombone Devil Evolved":
      return "../textures/BP03-082EN.png";
    case "Mischievous Zombie Evolved":
      return "../textures/BP03-087EN.png";
    case "Diamond Master Evolved":
      return "../textures/BP03-092EN.png";
    case "Wingy Chirpy Gemstone Evolved":
      return "../textures/BP03-095EN.png";
    case "Ruby Falcon Evolved":
      return "../textures/BP03-099EN.png";
    case "Pinion Prince Evolved":
      return "../textures/BP03-103EN.png";
    case "Alice, Wonderland Explorer Evolved":
      return "../textures/BP03-U07EN.png";
    case "Garuel, Seraphic Leo Evolved":
      return "../textures/BP03-113EN.png";
    case "Humpty Dumpty Evolved":
      return "../textures/BP03-116EN.png";
    case "Harbringer of the Night Evolved":
      return "../textures/BP03-120EN.png";

    case "Silence Suzuka Evolved":
      return "../textures/CP01-SP02EN.png";
    case "Tokai Teio Evolved":
      return "../textures/CP01-SP05EN.png";
    case "Agnes Tachyon Evolved":
      return "../textures/CP01-SP08EN.png";
    case "Special Week Evolved":
      return "../textures/CP01-SP11EN.png";
    case "Maruzensky Evolved":
      return "../textures/CP01-SP14EN.png";
    case "Mejiro McQueen Evolved":
      return "../textures/CP01-SP17EN.png";
    case "Carrot":
      return "../textures/CP01-085EN.png";

    case "Crystalia Tia Evolved":
      return "../textures/BP02-U01EN.png";
    case "Grand Archer Seiwyn Evolved":
      return "../textures/BP02-007EN.png";
    case "Crystalia Lily Evolved":
      return "../textures/BP02-009EN.png";
    case "Forest Gigas Evolved":
      return "../textures/BP02-015EN.png";
    case "Albert Levin Saber Evolved":
      return "../textures/BP02-019EN.png";
    case "Leonidas Evolved":
      return "../textures/BP02-023EN.png";
    case "Jeno, Levin Vanguard Evolved":
      return "../textures/BP02-026EN.png";
    case "Avant Blader Evolved":
      return "../textures/BP02-032EN.png";
    case "Daria Dimensional Witch Evolved":
      return "../textures/BP02-036EN.png";
    case "Anne, Belle oF Mysteria Evolved":
      return "../textures/BP02-U03EN.png";
    case "Remi & Rami, Witchy Duo Evolved":
      return "../textures/BP02-043EN.png";
    case "Craig, Wizard of Mysteria Evolved":
      return "../textures/BP02-047EN.png";
    case "Imperial Dragoon Evolved":
      return "../textures/BP02-U04EN.png";
    case "Neptune Evolved":
      return "../textures/BP02-056EN.png";
    case "Siegfried Evolved":
      return "../textures/BP02-060EN.png";
    case "Dragontamer Evolved":
      return "../textures/BP02-066EN.png";
    case "Soul Dealer Evolved":
      return "../textures/BP02-071EN.png";
    case "Azazel Evolved":
      return "../textures/BP02-074EN.png";
    case "Veight, Vampire Noble Evolved":
      return "../textures/BP02-077EN.png";
    case "Demonic Hedonist Evolved":
      return "../textures/BP02-083EN.png";
    case "Heavenly Aegis Evolved":
      return "../textures/BP02-087EN.png";
    case "Kaguya Evolved":
      return "../textures/BP02-U06EN.png";
    case "Radiance Angel Evolved":
      return "../textures/BP02-094EN.png";
    case "Soul Collector Evolved":
      return "../textures/BP02-100EN.png";
    case "Bahamut Evolved":
      return "../textures/BP02-U07EN.png";
    case "Archangel Reina Evolved":
      return "../textures/BP02-108EN.png";
    case "Unicorn Dancer Unicorn Evolved":
      return "../textures/BP02-111EN.png";
    case "Sektor Evolved":
      return "../textures/BP02-116EN.png";

    case "Priest of the Cudgel Evolved":
      return "../textures/SD06-004EN.png";
    case "Ardent Nun Evolved":
      return "../textures/SD06-010EN.png";
    case "Guardian Nun Evolved":
      return "../textures/SD06-012EN.png";
    case "Playful Necromancer Evolved":
      return "../textures/SD05-004EN.png";
    case "Lesser Mummy Evolved":
      return "../textures/SD05-011EN.png";
    case "Lilith Evolved":
      return "../textures/SD05-013EN.png";
    case "Dragon Warrior Evolved":
      return "../textures/SD04-004EN.png";
    case "Roc Evolved":
      return "../textures/SD04-010EN.png";
    case "Dragonrider Evolved":
      return "../textures/SD04-013EN.png";
    case "Demonflame Mage Evolved":
      return "../textures/SD03-004EN.png";
    case "Penguin Wizard Evolved":
      return "../textures/SD03-009EN.png";
    case "Sammy Wizard's Apprentice Evolved":
      return "../textures/SD03-011EN.png";
    case "Floral Fencer Evolved":
      return "../textures/SD02-004EN.png";
    case "Oathless Knight Evolved":
      return "../textures/SD02-011EN.png";
    case "Quickblader Evolved":
      return "../textures/SD02-013EN.png";
    case "Rose Gardener Evolved":
      return "../textures/SD01-004EN.png";
    case "Treant Evolved":
      return "../textures/SD01-010EN.png";
    case "Water Fairy Evolved":
      return "../textures/SD01-012EN.png";
    case "Ancient Elf Evolved":
      return "../textures/BP01-003EN.png";

    case "Rhinoceroach Evolved":
      return "../textures/BP01-005EN.png";
    case "Elven Princess Mage Evolved":
      return "../textures/BP01-010EN.png";
    case "Archer Evolved":
      return "../textures/BP01-019EN.png";
    case "Sea Queen Otohime Evolved":
      return "../textures/BP01-U02EN.png";
    case "Shadowed Assassin Evolved":
      return "../textures/BP01-030EN.png";
    case "Maid Leader Evolved":
      return "../textures/BP01-035EN.png";
    case "Fervid Soldier Evolved":
      return "../textures/BP01-044EN.png";
    case "Merlin Evolved":
      return "../textures/BP01-053EN.png";
    case "Ancient Alchemist Evolved":
      return "../textures/BP01-055EN.png";
    case "Spectral Wizard Evolved":
      return "../textures/BP01-060EN.png";
    case "Crafty Warlock Evolved":
      return "../textures/BP01-069EN.png";
    case "Dark Dragoon Forte Evolved U":
      return "../textures/BP01-U04EN.png";
    case "Shapeshifting Mage Evolved":
      return "../textures/BP01-082EN.png";
    case "Shenlong Evolved":
      return "../textures/BP01-087EN.png";
    case "Ivory Dragon Evolved":
      return "../textures/BP01-093EN.png";
    case "Cerberus Evolved":
      return "../textures/BP01-102EN.png";
    case "Righteous Devil Evolved":
      return "../textures/BP01-106EN.png";
    case "Crazed Executioner Evolved":
      return "../textures/BP01-111EN.png";
    case "Wardrobe Raider Evolved":
      return "../textures/BP01-123EN.png";
    case "Jeanne d'Arc Evolved":
      return "../textures/BP01-U06EN.png";
    case "Arch Priestess Laelia Evolved":
      return "../textures/BP01-130EN.png";
    case "Prism Priestess Evolved":
      return "../textures/BP01-136EN.png";
    case "Mainyu Evolved":
      return "../textures/BP01-145EN.png";
    case "Lucifer Evolved":
      return "../textures/BP01-153EN.png";
    case "Urd Evolved":
      return "../textures/BP01-156EN.png";
    case "Bellringer Angel Evolved":
      return "../textures/BP01-160EN.png";
    case "Goblinmount Demon Evolved":
      return "../textures/BP01-165EN.png";
    case "Goblin Evolved":
      return "../textures/BP01-172EN.png";
    case "Goliath Evolved":
      return "../textures/BP01-175EN.png";
    case "Healing Angel Evolved":
      return "../textures/BP01-178EN.png";

    case "Instant Potion TOKEN":
      return "../textures/bp09-t01.png";
    case "Eternal Potion TOKEN":
      return "../textures/bp09-t02.png";
    case "Mysterian Missle TOKEN":
      return "../textures/dsd01a-t01.png";
    case "Aftershock TOKEN":
      return "../textures/dsd01b-t01.png";

    case "Lloyd TOKEN":
      return "../textures/BP08-T01EN.png";
    case "Victoria TOKEN":
      return "../textures/BP08-T02EN.png";
    case "Otohime's Vanguard TOKEN":
      return "../textures/BP08-T03EN.png";

    case "Assembly Droid TOKEN":
      return "../textures/BP07-T01EN.png";
    case "Repair Mode TOKEN":
      return "../textures/BP07-T02EN.png";
    case "Naterran Great Tree TOKEN":
      return "../textures/BP07-T03EN.png";

    case "Enchanted Slippers TOKEN":
      return "../textures/PR-153EN.png";
    case "Enchanted Dress TOKEN":
      return "../textures/PR-154EN.png";
    case "Cute Earrings TOKEN":
      return "../textures/CP02-T01EN.png";
    case "Cool Earrings TOKEN":
      return "../textures/CP02-T04EN.png";
    case "Passion Earrings TOKEN":
      return "../textures/CP02-T07EN.png";
    case "Celestial Shikigami TOKEN":
      return "../textures/BP06-T01EN.png";
    case "Paper Shikigami TOKEN":
      return "../textures/BP06-T02EN.png";
    case "One-Tailed Fox TOKEN":
      return "../textures/BP06-T03EN.png";
    case "Destruction in White TOKEN":
      return "../textures/BP05-T01EN.png";
    case "Destruction in Black TOKEN":
      return "../textures/BP05-T02EN.png";
    case "Puppet TOKEN":
      return "../textures/BP05-T03EN.png";
    case "Ancient Artifact TOKEN":
      return "../textures/BP05-T04EN.png";
    case "Mystic Artifact TOKEN":
      return "../textures/BP05-T05EN.png";
    case "Serpent TOKEN":
      return "../textures/BP04-T01EN.png";
    case "Goblin King TOKEN":
      return "../textures/BP04-T02EN.png";
    case "Gargantuan Ghost TOKEN":
      return "../textures/BP03-T01EN.png";
    case "Crystalia Eve TOKEN":
      return "../textures/BP02-T01EN.png";
    case "Shield Guardian TOKEN":
      return "../textures/BP02-T02EN.png";
    case "Leonidas's Resolve TOKEN":
      return "../textures/BP02-T03EN.png";
    case "Magical Pawn TOKEN":
      return "../textures/BP02-T04EN.png";
    case "Megalorca TOKEN":
      return "../textures/BP02-T05EN.png";
    case "Hellflame Dragon TOKEN":
      return "../textures/BP02-T06EN.png";
    case "Draconic Weapon TOKEN":
      return "../textures/BP02-T07EN.png";
    case "Ephemeral Moon TOKEN":
      return "../textures/BP02-T08EN.png";
    case "Thorn Burst TOKEN":
      return "../textures/BP01-T01EN.png";
    case "Fairy Wisp TOKEN":
      return "../textures/BP01-T02EN.png";
    case "Fairy TOKEN":
      return "../textures/BP01-T03EN.png";
    case "Otohime's Bodyguard TOKEN":
      return "../textures/BP01-T04EN.png";
    case "Knight TOKEN":
      return "../textures/BP01-T05EN.png";
    case "Viking TOKEN":
      return "../textures/BP01-T06EN.png";
    case "Steelclad Knight TOKEN":
      return "../textures/BP01-T07EN.png";
    case "Strikeform Golem TOKEN":
      return "../textures/BP01-T08EN.png";
    case "Guardform Golem TOKEN":
      return "../textures/BP01-T09EN.png";
    case "Magic Sediment TOKEN":
      return "../textures/BP01-T10EN.png";
    case "Dragon TOKEN":
      return "../textures/BP01-T11EN.png";
    case "Mimi TOKEN":
      return "../textures/BP01-T12EN.png";
    case "Coco TOKEN":
      return "../textures/BP01-T13EN.png";
    case "Ghost TOKEN":
      return "../textures/BP01-T14EN.png";
    case "Forest Bat TOKEN":
      return "../textures/BP01-T15EN.png";
    case "Holy Falcon TOKEN":
      return "../textures/BP01-T16EN.png";
    case "Holy Tiger TOKEN":
      return "../textures/BP01-T17EN.png";
    case "Card":
      return "../textures/default.png";
    default:
      return "";
  }
};
